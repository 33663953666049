<template>
  <v-container
    style="max-width: 1300px !important"
    :class="showConfirmPage ? 'visible' : 'invisible'"
  >
    <v-form ref="form" v-model="valid1" @submit.prevent="submitform">
      <div class="row">
        <div class="col-md-12">
          <v-timeline direction="horizontal" line-inset="12">
            <v-timeline-item dot-color="success">
              <template v-slot:icon>
                <v-icon color="white">mdi-magnify</v-icon>
              </template>
            </v-timeline-item>

            <v-timeline-item dot-color="success">
              <template v-slot:icon>
                <v-icon color="white">mdi-airplane</v-icon>
              </template>
            </v-timeline-item>

            <v-timeline-item dot-color="primary">
              <template v-slot:icon>
                <v-icon color="white">mdi-account</v-icon>
              </template>
            </v-timeline-item>

            <v-timeline-item dot-color="grey">
              <template v-slot:icon>
                <v-icon color="white" size="20">mdi-cash-multiple</v-icon>
              </template>
            </v-timeline-item>
          </v-timeline>
        </div>

        <div class="col-lg-9 col-md-12 col-sm-12 mx-0 px-0">
          <div class="fare-show d-lg-none">
            <v-card rounded="2" max-height="500px" class="p-3 mx-1 mb-3">
              <div class="d-flex justify-content-between">
                <div>
                  <p class="m-0 totalPrice" style="font-weight: 500">
                    {{ $t("fareDetContent.totalPrice") }}
                  </p>
                </div>
                <div>
                  <p class="m-0 totalPrice" style="font-weight: 500">
                    {{ currency }} {{ totalAmountpassenger }}
                  </p>
                </div>
              </div>

              <div class="row mt-2">
                <div class="col-md-12">
                  <div class="row justify-content-between">
                    <div class="col-6">
                      <span style="font-size: 14px; font-weight: 500">{{
                        $t("fareDetContent.passengers")
                      }}</span>
                    </div>
                    <!-- <div class="col-md-4"></div> -->
                    <div class="col-6 text-end" style="font-size: 14px">
                      <span v-if="adultLength > 0"
                        >{{ adultLength }} x Adult</span
                      ><br />
                      <span v-if="childLength > 0"
                        >{{ childLength }} x Child</span
                      ><br />
                      <span v-if="infLength > 0"
                        >{{ infLength }} x Lap Infant</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-3">
                <hr class="mt-1 mb-2" />
                <div class="d-flex align-end mb-2">
                  <span
                    class="not-collapsed"
                    data-bs-toggle="collapse"
                    href="#collapse-Example"
                    aria-expanded="true"
                    aria-controls="collapse-Example"
                    style="
                      padding-right: 5px;
                      cursor: pointer;
                      font-size: 14px;
                      letter-spacing: 1px;
                    "
                  >
                    <span style="color: 0D6EFD; text-decoration: underline">{{
                      $t("fareDetContent.fareSummary")
                    }}</span>
                  </span>

                  <v-icon
                    size="18"
                    class="not-collapsed"
                    data-bs-toggle="collapse"
                    href="#collapse-Example"
                    aria-expanded="true"
                    aria-controls="collapse-Example"
                    v-model="isIcon"
                    style="
                      color: 0D6EFD;
                      cursor: pointer;
                      overflow-anchor: none;
                    "
                  >
                    {{
                      isIcon
                        ? "mdi-chevron-up-box-outline"
                        : "mdi-chevron-down-box-outline"
                    }}</v-icon
                  >
                </div>

                <div
                  class="p-1 collapse"
                  :class="{ show: isIcon }"
                  id="collapse-Example"
                >
                  <div class="row">
                    <div class="col-6 f-size-14">
                      {{ $t("fareDetContent.basePrice") }}<br />
                      {{ $t("fareDetContent.taxesFees") }}
                    </div>
                    <div class="col-6 f-size-14" align="center">
                      <div class="d-flex flex-row justify-end">
                        <div class="col-5 text-end">
                          {{ currency }} <br />
                          {{ currency }}
                        </div>
                        <div class="col-6 col-sm-3 text-end">
                          {{ totalbasePassanger }}<br />
                          {{ totalTaxPassanger }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <hr class="mt-1 mb-2" />
                <div class="d-flex align-end mb-2">
                  <span
                    class="not-collapsed"
                    style="
                      padding-right: 5px;
                      cursor: pointer;
                      font-size: 14px;
                      letter-spacing: 1px;
                    "
                  >
                    <a
                      class="baggage border-none"
                      style="
                        font-size: 14px;
                        color: rgb(13, 110, 253);
                        text-decoration: underline;
                      "
                    >
                      <span
                        data-bs-toggle="offcanvas"
                        data-bs-target="#staticBackdrop4"
                        aria-controls="staticBackdrop"
                        @click="getFareRules()"
                        >Baggage and Cancellation Rules</span
                      >
                    </a>
                    <div class="drawer-section">
                      <div
                        class="offcanvas offcanvas-end"
                        data-bs-backdrop="static"
                        tabindex="-1"
                        id="staticBackdrop4"
                        aria-labelledby="staticBackdropLabel"
                      >
                        <div
                          class="offcanvas-header"
                          style="
                            background: #5293cb !important;
                            color: #fff !important;
                          "
                        >
                          <h6 class="offcanvas-title" id="staticBackdrop4">
                            Baggage and Cancellation Rules
                          </h6>
                          <button
                            @click="closeBaggageRules"
                            type="button"
                            class="text-reset"
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                            color="white"
                          >
                            <v-icon>mdi-close</v-icon>
                          </button>
                        </div>
                        <div
                          class="offcanvas-body pt-0"
                          style="overflow-x: hidden"
                        >
                          <v-card class="mt-4">
                            <v-tabs v-model="tab" align-tabs="center" stacked>
                              <v-tab value="tab-1" class="m-0">
                                <v-icon>mdi-book-cancel-outline</v-icon>
                                Cancellation Rules
                              </v-tab>
                              <v-tab value="tab-2">
                                <v-icon>mdi-bag-checked</v-icon>
                                Baggage
                              </v-tab>
                            </v-tabs>

                            <v-card-text class="pt-0">
                              <keep-alive>
                                <v-window v-model="tab">
                                  <div v-if="tab === 'tab-1'">
                                    <h6 class="mt-2">Cancellation Rules:</h6>
                                    <div class="p-2 cancellationRules">
                                      <div
                                        v-if="fareRulesContent"
                                        v-html="fareRulesContent"
                                      ></div>
                                      <p v-else class="my-1">
                                        Check with Airline websites for
                                        Cancellation and Change Rules
                                      </p>
                                    </div>
                                  </div>
                                  <div v-if="tab === 'tab-2'">
                                    <h6 class="mt-1">Baggage Rules:</h6>
                                    <div
                                      class="p-2 rounded"
                                      v-for="(data, index) of roundresult"
                                      :key="index"
                                    >
                                      <div>
                                        <div
                                          style="
                                            font-size: 15px;
                                            font-weight: 500;
                                          "
                                        >
                                          {{
                                            data.Depature[0].Departure
                                              .AirportCode
                                          }}
                                          -
                                          {{
                                            data.Depature[
                                              data.Depature.length - 1
                                            ].Arrival.AirportCode
                                          }}
                                        </div>
                                        <div class="ms-2 mt-2">
                                          <!-- <div >
                                  
                                </div> -->
                                          <div
                                            class="d-flex flex-row"
                                            style="
                                              font-size: 14px;
                                              font-weight: 400;
                                              margin-left: 5px;
                                            "
                                          >
                                            <span>
                                              <v-icon
                                                color="gray"
                                                style="color: gray !important"
                                              >
                                                mdi-bag-checked
                                              </v-icon></span
                                            >
                                            <span>Carry-On:</span>
                                            <span
                                              v-if="data.depBaggage"
                                              class="ms-1"
                                            >
                                              {{
                                                data.depBaggage.PieceAllowance
                                                  .TotalQuantity > 0
                                                  ? 1 + " Piece"
                                                  : 1 + " Piece"
                                              }}
                                            </span>
                                          </div>
                                        </div>
                                        <div class="ms-2 mt-2">
                                          <!-- <div>
                                  
                                </div> -->
                                          <div
                                            class="d-flex flex-row"
                                            style="
                                              font-size: 14px;
                                              font-weight: 400;
                                              margin-left: 5px;
                                            "
                                          >
                                            <span>
                                              <v-icon
                                                color="gray"
                                                style="color: gray !important"
                                              >
                                                mdi-bag-suitcase
                                              </v-icon></span
                                            >
                                            <span> Checked Bag:</span>
                                            <span
                                              v-if="data.depBaggage"
                                              class="ms-1"
                                            >
                                              {{
                                                data.depBaggage.PieceAllowance
                                                  .TotalQuantity || 0
                                              }}

                                              {{
                                                data.depBaggage.PieceAllowance
                                                  .TotalQuantity > 1
                                                  ? "Pieces"
                                                  : "Piece"
                                              }}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                      <hr v-if="data.Return" />
                                      <div v-if="data.Return" class="mt-2">
                                        <div
                                          class="mt-1"
                                          style="
                                            font-size: 15px;
                                            font-weight: 500;
                                          "
                                        >
                                          {{
                                            data.Depature[
                                              data.Depature.length - 1
                                            ].Arrival.AirportCode
                                          }}
                                          -
                                          {{
                                            data.Depature[0].Departure
                                              .AirportCode
                                          }}
                                        </div>
                                        <!-- <div v-if="flightdatas.arrBaggage.PieceAllowance.TotalQuantity != 0 "></div> -->
                                        <div class="ms-2 mt-2">
                                          <!-- <div >
                                  
                                </div> -->
                                          <div
                                            class="d-flex flex-row"
                                            style="
                                              font-size: 14px;
                                              font-weight: 400;
                                              margin-left: 5px;
                                            "
                                          >
                                            <span>
                                              <v-icon
                                                color="gray"
                                                style="color: gray !important"
                                              >
                                                mdi-bag-checked
                                              </v-icon></span
                                            >
                                            <span>Carry-On:</span>
                                            <span class="ms-1">
                                              {{
                                                data.arrBaggage.PieceAllowance
                                                  .TotalQuantity > 0
                                                  ? 1 + " Piece"
                                                  : 1 + " Piece"
                                              }}
                                            </span>
                                          </div>
                                        </div>
                                        <div class="ms-2 mt-1">
                                          <!-- <div style="color: gray !important">
                                  
                                </div> -->
                                          <div
                                            class="d-flex flex-row"
                                            style="
                                              font-size: 14px;
                                              font-weight: 400;
                                              margin-left: 5px;
                                            "
                                          >
                                            <span>
                                              <v-icon
                                                color="gray"
                                                style="color: gray !important"
                                              >
                                                mdi-bag-suitcase
                                              </v-icon></span
                                            >
                                            <span> Checked Bag:</span>
                                            <!-- <span class="ms-1"> {{res.arrBaggage.PieceAllowance.TotalQuantity}}Piece</span> -->
                                            <span class="ms-1">
                                              {{
                                                data.arrBaggage.PieceAllowance
                                                  .TotalQuantity || 0
                                              }}
                                              {{
                                                data.arrBaggage.PieceAllowance
                                                  .TotalQuantity > 1
                                                  ? "Pieces"
                                                  : "Piece"
                                              }}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div v-if="onewayresult">
                                      <div
                                        class="p-2 rounded"
                                        v-for="(data, index) of onewayresult"
                                        :key="index"
                                      >
                                        <div>
                                          <div
                                            style="
                                              font-size: 15px;
                                              font-weight: 500;
                                            "
                                          >
                                            {{
                                              data.Depature[0].Departure
                                                .AirportCode
                                            }}
                                            -
                                            {{
                                              data.Depature[
                                                data.Depature.length - 1
                                              ].Arrival.AirportCode
                                            }}
                                          </div>
                                          <div class="ms-2 mt-2">
                                            <!-- <div >
                                  
                                </div> -->
                                            <div
                                              class="d-flex flex-row"
                                              style="
                                                font-size: 14px;
                                                font-weight: 400;
                                                margin-left: 5px;
                                              "
                                            >
                                              <span>
                                                <v-icon
                                                  color="gray"
                                                  style="color: gray !important"
                                                >
                                                  mdi-bag-checked
                                                </v-icon></span
                                              >
                                              <span>Carry-On:</span>
                                              <span
                                                v-if="data.BaggageData"
                                                class="ms-1"
                                              >
                                                {{
                                                  data.BaggageData
                                                    .PieceAllowance
                                                    .TotalQuantity > 0
                                                    ? 1 + " Piece"
                                                    : 1 + " Piece"
                                                }}
                                              </span>
                                            </div>
                                          </div>
                                          <div class="ms-2 mt-2">
                                            <!-- <div>
                                  
                                </div> -->
                                            <div
                                              class="d-flex flex-row"
                                              style="
                                                font-size: 14px;
                                                font-weight: 400;
                                                margin-left: 5px;
                                              "
                                            >
                                              <span>
                                                <v-icon
                                                  color="gray"
                                                  style="color: gray !important"
                                                >
                                                  mdi-bag-suitcase
                                                </v-icon></span
                                              >
                                              <span> Checked Bag:</span>
                                              <span class="ms-1">
                                                {{
                                                  data.BaggageData
                                                    .PieceAllowance
                                                    .TotalQuantity || 0
                                                }}

                                                {{
                                                  data.BaggageData
                                                    .PieceAllowance
                                                    .TotalQuantity > 1
                                                    ? "Pieces"
                                                    : "Piece"
                                                }}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div v-if="multiresult">
                                      <div
                                        class="border p-2 rounded"
                                        v-for="(data, index) of multiresult"
                                        :key="index"
                                      >
                                        <div>
                                          <div
                                            style="
                                              font-size: 15px;
                                              font-weight: 500;
                                            "
                                          >
                                            {{
                                              data.Flight1[0].Departure
                                                .AirportCode
                                            }}
                                            -
                                            {{
                                              data.Flight1[
                                                data.Flight1.length - 1
                                              ].Arrival.AirportCode
                                            }}
                                          </div>
                                          <div class="ms-2 mt-2">
                                            <div
                                              class="d-flex flex-row"
                                              style="
                                                font-size: 14px;
                                                font-weight: 400;
                                                margin-left: 5px;
                                              "
                                            >
                                              <span>
                                                <v-icon
                                                  color="gray"
                                                  style="color: gray !important"
                                                >
                                                  mdi-bag-checked
                                                </v-icon></span
                                              >
                                              <span>Carry-On: </span>
                                              <span class="">{{
                                                data.BaggageData1.PieceAllowance
                                                  .TotalQuantity > 0
                                                  ? 1 + " Piece"
                                                  : 1 + " Piece"
                                              }}</span>
                                            </div>
                                          </div>
                                          <div class="ms-2 mt-2">
                                            <!-- <div>
                                  
                                </div> -->
                                            <div
                                              class="d-flex flex-row"
                                              style="
                                                font-size: 14px;
                                                font-weight: 400;
                                                margin-left: 5px;
                                              "
                                            >
                                              <span>
                                                <v-icon
                                                  color="gray"
                                                  style="color: gray !important"
                                                >
                                                  mdi-bag-suitcase
                                                </v-icon></span
                                              >
                                              <span> Checked Bag: </span>
                                              <span class="mt-1">
                                                {{
                                                  data.BaggageData1
                                                    .PieceAllowance
                                                    .TotalQuantity || 0
                                                }}{{
                                                  data.BaggageData1
                                                    .PieceAllowance
                                                    .TotalQuantity > 1
                                                    ? " Pieces"
                                                    : " Piece"
                                                }}</span
                                              >
                                            </div>
                                          </div>

                                          <div class="ms-2 mt-2">
                                            <!-- <div >
                                  
                                </div> -->
                                          </div>
                                        </div>
                                        <hr v-if="data.Flight2" />
                                        <div v-if="data.Flight2" class="mt-2">
                                          <div
                                            class="mt-1"
                                            style="
                                              font-size: 15px;
                                              font-weight: 500;
                                            "
                                          >
                                            {{
                                              data.Flight2[0].Departure
                                                .AirportCode
                                            }}
                                            -
                                            {{
                                              data.Flight2[
                                                data.Flight2.length - 1
                                              ].Arrival.AirportCode
                                            }}
                                          </div>
                                          <!-- <div v-if="flightdatas.arrBaggage.PieceAllowance.TotalQuantity != 0 "></div> -->
                                          <div class="ms-2 mt-2">
                                            <!-- <div >
                                  
                                </div> -->
                                            <div
                                              class="d-flex flex-row"
                                              style="
                                                font-size: 14px;
                                                font-weight: 400;
                                                margin-left: 5px;
                                              "
                                            >
                                              <span>
                                                <v-icon
                                                  color="gray"
                                                  style="color: gray !important"
                                                >
                                                  mdi-bag-checked
                                                </v-icon></span
                                              >
                                              <span>Carry-On: </span>
                                              <span class="">{{
                                                data.BaggageData2.PieceAllowance
                                                  .TotalQuantity > 0
                                                  ? 1 + " Piece"
                                                  : 1 + " Piece"
                                              }}</span>
                                            </div>
                                          </div>
                                          <div class="ms-2 mt-2">
                                            <!-- <div>
                                  
                                </div> -->
                                            <div
                                              class="d-flex flex-row"
                                              style="
                                                font-size: 14px;
                                                font-weight: 400;
                                                margin-left: 5px;
                                              "
                                            >
                                              <span>
                                                <v-icon
                                                  color="gray"
                                                  style="color: gray !important"
                                                >
                                                  mdi-bag-suitcase
                                                </v-icon></span
                                              >
                                              <span> Checked Bag: </span>
                                              <span class="mt-1">
                                                {{
                                                  data.BaggageData2
                                                    .PieceAllowance
                                                    .TotalQuantity || 0
                                                }}{{
                                                  data.BaggageData2
                                                    .PieceAllowance
                                                    .TotalQuantity > 1
                                                    ? " Pieces"
                                                    : " Piece"
                                                }}</span
                                              >
                                            </div>
                                          </div>
                                        </div>
                                        <hr v-if="data.Flight3" />
                                        <div v-if="data.Flight3" class="mt-2">
                                          <div
                                            class="mt-1"
                                            style="
                                              font-size: 15px;
                                              font-weight: 500;
                                            "
                                          >
                                            {{
                                              data.Flight3[0].Departure
                                                .AirportCode
                                            }}
                                            -
                                            {{
                                              data.Flight3[
                                                data.Flight3.length - 1
                                              ].Arrival.AirportCode
                                            }}
                                          </div>
                                          <!-- <div v-if="flightdatas.arrBaggage.PieceAllowance.TotalQuantity != 0 "></div> -->
                                          <div class="ms-2 mt-2">
                                            <!-- <div >
                                  
                                </div> -->
                                            <div
                                              class="d-flex flex-row"
                                              style="
                                                font-size: 14px;
                                                font-weight: 400;
                                                margin-left: 5px;
                                              "
                                            >
                                              <span>
                                                <v-icon
                                                  color="gray"
                                                  style="color: gray !important"
                                                >
                                                  mdi-bag-checked
                                                </v-icon></span
                                              >
                                              <span>Carry-On: </span>
                                              <span class="">{{
                                                data.BaggageData3.PieceAllowance
                                                  .TotalQuantity > 0
                                                  ? 1 + " Piece"
                                                  : 1 + " Piece"
                                              }}</span>
                                            </div>
                                          </div>
                                          <div class="ms-2 mt-2">
                                            <!-- <div>
                                  
                                </div> -->
                                            <div
                                              class="d-flex flex-row"
                                              style="
                                                font-size: 14px;
                                                font-weight: 400;
                                                margin-left: 5px;
                                              "
                                            >
                                              <span>
                                                <v-icon
                                                  color="gray"
                                                  style="color: gray !important"
                                                >
                                                  mdi-bag-suitcase
                                                </v-icon></span
                                              >
                                              <span> Checked Bag: </span>
                                              <span class="mt-1">
                                                {{
                                                  data.BaggageData3
                                                    .PieceAllowance
                                                    .TotalQuantity || 0
                                                }}{{
                                                  data.BaggageData3
                                                    .PieceAllowance
                                                    .TotalQuantity > 1
                                                    ? " Pieces"
                                                    : " Piece"
                                                }}</span
                                              >
                                            </div>
                                          </div>
                                        </div>
                                        <hr v-if="data.Flight4" />
                                        <div v-if="data.Flight4" class="mt-2">
                                          <div
                                            class="mt-1"
                                            style="
                                              font-size: 15px;
                                              font-weight: 500;
                                            "
                                          >
                                            {{
                                              data.Flight4[0].Departure
                                                .AirportCode
                                            }}
                                            -
                                            {{
                                              data.Flight4[
                                                data.Flight4.length - 1
                                              ].Arrival.AirportCode
                                            }}
                                          </div>
                                          <!-- <div v-if="flightdatas.arrBaggage.PieceAllowance.TotalQuantity != 0 "></div> -->
                                          <div class="ms-2 mt-2">
                                            <!-- <div >
                                  
                                </div> -->
                                            <div
                                              class="d-flex flex-row"
                                              style="
                                                font-size: 14px;
                                                font-weight: 400;
                                                margin-left: 5px;
                                              "
                                            >
                                              <span>
                                                <v-icon
                                                  color="gray"
                                                  style="color: gray !important"
                                                >
                                                  mdi-bag-checked
                                                </v-icon></span
                                              >
                                              <span>Carry-On: </span>
                                              <span class="">{{
                                                data.BaggageData4.PieceAllowance
                                                  .TotalQuantity > 0
                                                  ? 1 + " Piece"
                                                  : 1 + " Piece"
                                              }}</span>
                                            </div>
                                          </div>
                                          <div class="d-flex ms-2 mt-2">
                                            <!-- <div>
                                  
                                </div> -->
                                            <div
                                              style="
                                                font-size: 14px;
                                                font-weight: 400;
                                                margin-left: 5px;
                                              "
                                            >
                                              <span>
                                                <v-icon
                                                  color="gray"
                                                  style="color: gray !important"
                                                >
                                                  mdi-bag-suitcase
                                                </v-icon></span
                                              >
                                              <span> Checked Bag: </span>
                                              <span class="mt-1">
                                                {{
                                                  data.BaggageData4
                                                    .PieceAllowance
                                                    .TotalQuantity || 0
                                                }}{{
                                                  data.BaggageData4
                                                    .PieceAllowance
                                                    .TotalQuantity > 1
                                                    ? " Pieces"
                                                    : " Piece"
                                                }}</span
                                              >
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </v-window>
                              </keep-alive>
                            </v-card-text>
                          </v-card>
                        </div>
                      </div>
                    </div>
                  </span>
                </div>
                <hr class="mt-1 mb-2" />

                <p class="mt-2 mb-1" style="font-size: 12px">
                  {{ $t("fareDetContent.priceInfo1") }} {{ metaCurrency }} .{{
                    $t("fareDetContent.priceInfo2")
                  }}
                </p>
              </div>
            </v-card>
          </div>

          <div v-for="(data, index) of roundresult" :key="index">
            <v-card class="p-3 card-space" v-if="itineraryInfo">
              <div class="row">
                <h3 class="col-12 col-md-6">
                  {{ $t("flightInfoContent.headings.heading1") }}
                </h3>
              </div>

              <div class="mt-2">
                <v-card>
                  <div
                    class="row d-flex justify-content-between align-center pt-2 pb-2 m-auto headfrom"
                    style="background-color: #edf6fd"
                  >
                    <div class="col-md-2 col-4">
                      <h5 class="m-0 font-change-14">
                        {{ getdepcode(data.Depature) }} -

                        {{ getarrcode(data.Depature) }}
                      </h5>
                    </div>
                    <div class="col-md-3 col-5 text-lg-start text-center">
                      <h6 class="m-0 font-change-11">
                        {{ depdate(data.Depature) }}
                      </h6>
                    </div>
                    <div
                      class="col-md-2 col-3 text-lg-start ps-sm-0 ps-2 text-end"
                    >
                      <span class="stopsText">{{
                        getStop(data.Depature) > 0
                          ? getStop(data.Depature) +
                            " " +
                            $t("flightInfoContent.contents.stops")
                          : $t("flightInfoContent.contents.nonStop")
                      }}</span>
                    </div>
                    <div class="col-md-4 col-10 text-sm-start">
                      <span class="f-size-14 font-change-11"
                        >{{ $t("flightInfoContent.contents.tripDuration") }}
                        {{ data.Depaturejourney.Time }}</span
                      >
                    </div>
                    <div
                      class="col-md-2 col-2 d-flex d-md-none flex-md-column justify-md-start class-box"
                      :class="{ 'd-none': !data.cabinClass }"
                    >
                      <div class="d-flex align-center class-type">
                        <v-icon size="15px" color="#ccc">
                          mdi-seat-passenger
                        </v-icon>

                        <span class="f-size-12" style="padding-left: 5px">
                          {{ data.cabinClass }}
                        </span>
                      </div>
                    </div>
                    <!-- <div class="col-md-1 p-0 col-2">
               <a href="/search" class="text-decoration-none f-size-14 font-change-11">Change</a>
             </div> -->
                  </div>

                  <div class="px-2" style="max-width: 100%">
                    <div
                      class="row mt-3 mb-2"
                      v-for="(data1, index) of data.Depature"
                      :key="index"
                    >
                      <div class="col-md-3 col-3 mb-3 mb-md-0">
                        <div class="row ms-sm-1 ms-0 w-100">
                          <div class="col-md-4 col-12 airline-logo">
                            <v-img
                              :src="data1.MarketingCarrier.logo"
                              width="30px"
                            ></v-img>
                          </div>
                          <div class="col-md-8 col-12 airline-profile">
                            <p class="f-size-13 airline-name m-0">
                              {{ data1.MarketingCarrier.Name }}

                              <br />
                              <span class="f-size-12 airline-id">
                                {{ data1.MarketingCarrier.AirlineID }} -
                                {{ data1.MarketingCarrier.FlightNumber }}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-7 col-9">
                        <div class="row d-flex">
                          <div class="col-sm-3 p-0">
                            <span class="f-size-10 airline-date">{{
                              getdated(data1.Departure.Date)
                            }}</span>
                            <span
                              class="f-size-13 airline-time"
                              style="margin-left: 5px"
                              >{{ getflightdep(data1) }}</span
                            >
                          </div>
                          <div
                            class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0"
                            style="margin-bottom: 4px"
                          >
                            <span class="fw-500"
                              >{{ data1.Departure.AirportCode }} </span
                            >, {{ data1.Departure.AirportName }}
                          </div>
                        </div>

                        <div class="row d-flex">
                          <div class="col-sm-3 p-0">
                            <span class="f-size-10 airline-date">{{
                              getdated1(data1.Arrival.Date)
                            }}</span>
                            <span
                              class="f-size-13 airline-time"
                              style="margin-left: 5px"
                              >{{ getflightarr(data1) }}</span
                            >
                          </div>

                          <div
                            class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0"
                            style="margin-bottom: 4px"
                          >
                            <span class="fw-500">{{
                              data1.Arrival.AirportCode
                            }}</span
                            >, {{ data1.Arrival.AirportName }}
                          </div>
                        </div>
                      </div>

                      <div
                        class="col-md-2 col-12 d-md-flex d-none flex-md-column justify-md-start class-box"
                        :class="{ 'd-none': !data.cabinClass }"
                      >
                        <div class="d-flex align-center class-type">
                          <v-icon size="15px" color="#ccc">
                            mdi-seat-passenger
                          </v-icon>

                          <span class="f-size-12" style="padding-left: 5px">
                            {{ data.cabinClass }}
                          </span>
                        </div>

                        <!-- <div class="d-flex ms-md-0 ms-2 class-type">
                          <v-icon size="15px" color="#ccc">
                            mdi-food-fork-drink
                          </v-icon>

                          <span class="f-size-12" style="padding-left: 5px">
                            Meal provided
                          </span>
                        </div> -->
                      </div>

                      <div class="mt-3">
                        <div class="d-flex position-relative">
                          <hr
                            v-if="data.Depature.length - 1 !== index"
                            style="
                              width: 100%;
                              position: absolute;
                              margin-top: 8px !important;
                              margin-bottom: 0px !important;
                            "
                          />
                          <div
                            class="d-flex justify-center"
                            v-if="data.Depature.length - 1 !== index"
                            style="
                              width: 180px;
                              background: antiquewhite;
                              border-radius: 18px;
                              z-index: 1;
                              margin: auto;
                            "
                          >
                            <div class="d-flex align-center">
                              <span class="f-size-10"
                                >{{ data.layoverTimes1[index] }} Layover
                                Time</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-card>
              </div>

              <!-- Return Flight Start -->

              <div class="mt-3">
                <v-card>
                  <div
                    class="row d-flex justify-content-between align-center pt-2 pb-2 m-auto headfrom"
                    style="background-color: #edf6fd"
                  >
                    <div class="col-md-2 col-4">
                      <h5 class="m-0 font-change-14">
                        {{ getstart(data.Return) }} - {{ getend(data.Return) }}
                      </h5>
                    </div>
                    <div class="col-md-3 col-5 text-lg-start text-center">
                      <h6 class="m-0 font-change-11">
                        {{ getstartdate(data.Return) }}
                      </h6>
                    </div>
                    <div class="col-md-2 col-3 text-lg-start text-end">
                      <span class="stopsText"
                        >{{ getstop1(data.Return) }}
                        {{ $t("flightInfoContent.contents.stops") }}</span
                      >
                    </div>
                    <div class="col-md-4 col-10 text-sm-start">
                      <span class="f-size-14 font-change-11"
                        >{{ $t("flightInfoContent.contents.tripDuration") }}
                        {{ data.Returnjourney.Time }}</span
                      >
                    </div>
                    <div
                      class="col-md-2 col-2 d-flex d-md-none flex-md-column justify-md-start class-box"
                      :class="{ 'd-none': !data.cabinClass }"
                    >
                      <div class="d-flex align-center class-type">
                        <v-icon size="15px" color="#ccc">
                          mdi-seat-passenger
                        </v-icon>

                        <span class="f-size-12" style="padding-left: 5px">
                          {{ data.cabinClass }}
                        </span>
                      </div>
                    </div>
                    <!-- <div class="col-md-1 col-2 p-0">
               <a href="/search" class="text-decoration-none f-size-14  font-change-11">Change</a>
             </div> -->
                  </div>

                  <div class="px-2" style="max-width: 100%">
                    <div v-for="(data1, index) of data.Return" :key="index">
                      <div class="row mt-3 mb-2">
                        <div class="col-md-3 col-3 mb-3 mb-md-0">
                          <div class="row ms-sm-1 ms-0 w-100">
                            <div class="col-md-4 col-12 airline-logo">
                              <v-img
                                :src="data1.MarketingCarrier.logo"
                                width="30px"
                              ></v-img>
                            </div>
                            <div class="col-md-8 col-12 airline-profile">
                              <p class="f-size-13 airline-name m-0">
                                {{ data1.MarketingCarrier.Name }}

                                <br />
                                <span class="f-size-12 airline-id">
                                  {{ data1.MarketingCarrier.AirlineID }} -
                                  {{ data1.MarketingCarrier.FlightNumber }}
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-7 col-9">
                          <div class="row d-flex">
                            <div class="col-sm-3 p-0">
                              <span class="f-size-10 airline-date">{{
                                getdated(data1.Departure.Date)
                              }}</span>

                              <span
                                class="f-size-13 airline-time"
                                style="margin-left: 5px"
                                >{{ getflightdep(data1) }}</span
                              >
                            </div>
                            <div
                              class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0"
                              style="margin-bottom: 4px"
                            >
                              <span class="fw-500"
                                >{{ data1.Departure.AirportCode }} </span
                              >, {{ data1.Departure.AirportName }}
                            </div>
                          </div>

                          <div class="row d-flex">
                            <div class="col-sm-3 p-0">
                              <span class="f-size-10 airline-date">{{
                                getdated1(data1.Arrival.Date)
                              }}</span>
                              <span
                                class="f-size-13 airline-time"
                                style="margin-left: 5px"
                                >{{ getflightarr(data1) }}</span
                              >
                            </div>

                            <div
                              class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0"
                              style="margin-bottom: 4px"
                            >
                              <span class="fw-500">{{
                                data1.Arrival.AirportCode
                              }}</span
                              >, {{ data1.Arrival.AirportName }}
                            </div>
                          </div>
                        </div>

                        <div
                          class="col-md-2 col-12 d-md-flex d-none flex-md-column justify-md-start class-box"
                          :class="{ 'd-none': !data.cabinClass }"
                        >
                          <div class="d-flex align-center class-type">
                            <v-icon size="15px" color="#ccc">
                              mdi-seat-passenger
                            </v-icon>

                            <span class="f-size-12" style="padding-left: 5px">
                              {{ data.cabinClass }}
                            </span>
                          </div>

                          <!-- <div class="d-flex ms-md-0 ms-2 class-type">
                            <v-icon size="15px" color="#ccc">
                              mdi-food-fork-drink
                            </v-icon>

                            <span class="f-size-12" style="padding-left: 5px">
                              Meal provided
                            </span>
                          </div> -->
                        </div>
                      </div>

                      <div class="mt-3">
                        <div class="d-flex position-relative">
                          <hr
                            v-if="index !== data.Return.length - 1"
                            style="
                              width: 100%;
                              position: absolute;
                              margin-top: 8px !important;
                              margin-bottom: 0px !important;
                            "
                          />
                          <div
                            class="d-flex justify-center"
                            v-if="index !== data.Return.length - 1"
                            style="
                              width: 180px;
                              background: antiquewhite;
                              border-radius: 18px;
                              z-index: 1;
                              margin: auto;
                            "
                          >
                            <div class="d-flex align-center">
                              <span class="f-size-10"
                                >{{ data.layoverTimes2[index] }} Layover
                                Time</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-card>
              </div>
              <!-- Return Flight End -->
              <!-- <div>
     <span
       @click="itinerary()"
       class="text-decoration-none text-info"
       style="cursor: pointer"
       >Itinerary Details</span
     >&nbsp; &nbsp; &nbsp;
     <span
       ><span
         @click="baggage()"
         class="text-decoration-none text-info"
         style="cursor: pointer"
         >Baggage Info</span
       ></span
     >
   </div> -->
            </v-card>
          </div>

          <div v-for="(data, index) of onewayresult" :key="index">
            <v-card class="p-3 card-space" v-if="itineraryInfo1">
              <div class="row">
                <h3 class="col-12 col-md-6">Itinerary Information</h3>
              </div>

              <div class="mt-2">
                <v-card>
                  <div
                    class="row d-flex justify-content-between align-center pt-2 pb-2 m-auto headfrom"
                    style="background-color: #edf6fd"
                  >
                    <div class="col-md-2 col-4">
                      <h5 class="m-0 font-change-14">
                        {{ getdepcode(data.Depature) }} -

                        {{ getarrcode(data.Depature) }}
                      </h5>
                    </div>
                    <div class="col-md-3 col-5 text-lg-start text-center">
                      <h6 class="m-0 font-change-11">
                        {{ depdate(data.Depature) }}
                      </h6>
                    </div>
                    <div class="col-md-2 col-3 text-lg-start text-end">
                      <span class="stopsText">{{
                        getStop(data.Depature) > 0
                          ? getStop(data.Depature) +
                            " " +
                            $t("flightInfoContent.contents.stops")
                          : $t("flightInfoContent.contents.nonStop")
                      }}</span>
                    </div>
                    <div class="col-md-4 col-10 text-sm-start">
                      <span class="f-size-14 font-change-11"
                        >{{ $t("flightInfoContent.contents.tripDuration") }}
                        {{ data.Depaturejourney.Time }}</span
                      >
                    </div>
                    <div
                      class="col-md-2 col-2 d-flex d-md-none flex-md-column justify-md-start class-box"
                      :class="{ 'd-none': !data.cabinClass }"
                    >
                      <div class="d-flex align-center class-type">
                        <v-icon size="15px" color="#ccc">
                          mdi-seat-passenger
                        </v-icon>

                        <span class="f-size-12" style="padding-left: 5px">
                          {{ data.cabinClass }}
                        </span>
                      </div>
                    </div>
                    <!-- <div class="col-md-1 p-0 col-2">
               <a href="/search" class="text-decoration-none f-size-14 font-change-11">Change</a>
             </div> -->
                  </div>

                  <div class="px-2" style="max-width: 100%">
                    <div
                      class="row mt-3 mb-2"
                      v-for="(data1, index) of data.Depature"
                      :key="index"
                    >
                      <div class="col-md-3 col-3 mb-3 mb-md-0">
                        <div class="row ms-sm-1 ms-0 w-100">
                          <div class="col-md-4 col-12 airline-logo">
                            <v-img
                              :src="data1.MarketingCarrier.logo"
                              width="30px"
                            ></v-img>
                          </div>
                          <div class="col-md-8 col-12 airline-profile">
                            <p class="f-size-13 airline-name m-0">
                              {{ data1.MarketingCarrier.Name }}

                              <br />
                              <span class="f-size-12 airline-id">
                                {{ data1.MarketingCarrier.AirlineID }} -
                                {{ data1.MarketingCarrier.FlightNumber }}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-7 col-9">
                        <div class="row d-flex">
                          <div class="col-sm-3 p-0">
                            <span class="f-size-10 airline-date">{{
                              getdated(data1.Departure.Date)
                            }}</span>
                            <span
                              class="f-size-13 airline-time"
                              style="margin-left: 5px"
                              >{{ getflightdep(data1) }}</span
                            >
                          </div>
                          <div
                            class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0"
                            style="margin-bottom: 4px"
                          >
                            <span class="fw-500"
                              >{{ data1.Departure.AirportCode }} </span
                            >, {{ data1.Departure.AirportName }}
                          </div>
                        </div>

                        <div class="row d-flex">
                          <div class="col-sm-3 p-0">
                            <span class="f-size-10 airline-date">{{
                              getdated1(data1.Arrival.Date)
                            }}</span>
                            <span
                              class="f-size-13 airline-time"
                              style="margin-left: 5px"
                              >{{ getflightarr(data1) }}</span
                            >
                          </div>

                          <div
                            class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0"
                            style="margin-bottom: 4px"
                          >
                            <span class="fw-500">{{
                              data1.Arrival.AirportCode
                            }}</span>
                            , {{ data1.Arrival.AirportName }}
                          </div>
                        </div>
                      </div>

                      <div
                        class="col-md-2 col-12 d-md-flex d-none flex-md-column justify-md-start class-box"
                        :class="{ 'd-none': !data.cabinClass }"
                      >
                        <div class="d-flex align-center class-type">
                          <v-icon size="15px" color="#ccc">
                            mdi-seat-passenger
                          </v-icon>

                          <span class="f-size-12" style="padding-left: 5px">
                            {{ data.cabinClass }}
                          </span>
                        </div>

                        <!-- <div class="d-flex ms-md-0 ms-2 class-type">
                          <v-icon size="15px" color="#ccc">
                            mdi-food-fork-drink
                          </v-icon>

                          <span class="f-size-12" style="padding-left: 5px">
                            Meal provided
                          </span>
                        </div> -->
                      </div>

                      <div class="mt-3">
                        <div class="d-flex position-relative">
                          <hr
                            v-if="data.Depature.length - 1 !== index"
                            style="
                              width: 100%;
                              position: absolute;
                              margin-top: 8px !important;
                              margin-bottom: 0px !important;
                            "
                          />
                          <div
                            class="d-flex justify-center"
                            v-if="data.Depature.length - 1 !== index"
                            style="
                              width: 180px;
                              background: antiquewhite;
                              border-radius: 18px;
                              z-index: 1;
                              margin: auto;
                            "
                          >
                            <div class="d-flex align-center">
                              <span class="f-size-10"
                                >{{ data.layoverTimes[index] }} Layover
                                Time</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-card>
              </div>
            </v-card>
          </div>

          <div v-for="(data, index) of multiresult" :key="index">
            <v-card class="p-3 card-space">
              <div class="row">
                <h3 class="col-12 col-md-6">Itinerary Information</h3>
              </div>

              <div class="mt-2">
                <v-card>
                  <div
                    class="row d-flex justify-content-between align-center pt-2 pb-2 m-auto headfrom"
                    style="background-color: #edf6fd"
                  >
                    <div class="col-md-2 col-4">
                      <h5 class="m-0 font-change-14">
                        {{ getdepcode(data.Flight1) }} -

                        {{ getarrcode(data.Flight1) }}
                      </h5>
                    </div>
                    <div class="col-md-3 col-5 text-lg-start text-center">
                      <h6 class="m-0 font-change-11">
                        {{ depdate(data.Flight1) }}
                      </h6>
                    </div>
                    <div class="col-md-2 col-3 text-lg-start text-end">
                      <span class="stopsText">{{
                        getStop(data.Flight1) > 0
                          ? getStop(data.Flight1) +
                            " " +
                            $t("flightInfoContent.contents.stops")
                          : $t("flightInfoContent.contents.nonStop")
                      }}</span>
                    </div>
                    <div class="col-md-4 col-10 text-sm-start">
                      <span class="f-size-14 font-change-11"
                        >{{ $t("flightInfoContent.contents.tripDuration") }}
                        {{ data.Flight1JourneyTime.Time }}</span
                      >
                    </div>
                    <div
                      class="col-md-2 col-2 d-md-none d-flex flex-md-column justify-md-start class-box"
                      :class="{ 'd-none': !data.cabinClass }"
                    >
                      <div class="d-flex align-center class-type">
                        <v-icon size="15px" color="#ccc">
                          mdi-seat-passenger
                        </v-icon>

                        <span class="f-size-12">
                          {{ data.cabinClass }}
                        </span>
                      </div>
                    </div>
                    <!-- <div class="col-md-1 p-0 col-2">
            <a href="/search" class="text-decoration-none f-size-14 font-change-11">Change</a>
          </div> -->
                  </div>

                  <div class="px-2" style="max-width: 100%">
                    <div
                      class="row mt-3 mb-2"
                      v-for="(data1, index) of data.Flight1"
                      :key="index"
                    >
                      <div class="col-md-3 col-3 mb-3 mb-md-0">
                        <div class="row ms-sm-1 ms-0 w-100">
                          <div class="col-md-4 col-12 airline-logo">
                            <v-img
                              :src="data1.MarketingCarrier.logo"
                              width="30px"
                            ></v-img>
                          </div>
                          <div class="col-md-8 col-12 airline-profile">
                            <p class="f-size-13 airline-name m-0">
                              {{ data1.MarketingCarrier.Name }}

                              <br />
                              <span class="f-size-12 airline-id">
                                {{ data1.MarketingCarrier.AirlineID }} -
                                {{ data1.MarketingCarrier.FlightNumber }}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-7 col-9">
                        <div class="row d-flex">
                          <div class="col-sm-3 p-0">
                            <span class="f-size-10 airline-date">{{
                              getdated(data1.Departure.Date)
                            }}</span>
                            <span
                              class="f-size-13 airline-time"
                              style="margin-left: 5px"
                              >{{ getflightdep(data1) }}</span
                            >
                          </div>
                          <div
                            class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0"
                            style="margin-bottom: 4px"
                          >
                            <span class="fw-500"
                              >{{ data1.Departure.AirportCode }} </span
                            >, {{ data1.Departure.AirportName }}
                          </div>
                        </div>

                        <div class="row d-flex">
                          <div class="col-sm-3 p-0">
                            <span class="f-size-10 airline-date">{{
                              getdated1(data1.Arrival.Date)
                            }}</span>
                            <span
                              class="f-size-13 airline-time"
                              style="margin-left: 5px"
                              >{{ getflightarr(data1) }}</span
                            >
                          </div>

                          <div
                            class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0"
                            style="margin-bottom: 4px"
                          >
                            <span class="fw-500">{{
                              data1.Arrival.AirportCode
                            }}</span>
                            , {{ data1.Arrival.AirportName }}
                          </div>
                        </div>
                      </div>

                      <div
                        class="col-md-2 col-12 d-md-flex d-none flex-md-column justify-md-start class-box"
                        :class="{ 'd-none': !data.cabinClass }"
                      >
                        <div class="d-flex align-center class-type">
                          <v-icon size="15px" color="#ccc">
                            mdi-seat-passenger
                          </v-icon>

                          <span class="f-size-12" style="padding-left: 5px">
                            {{ data.cabinClass }}
                          </span>
                        </div>

                        <!-- <div class="d-flex ms-md-0 ms-2 class-type">
                          <v-icon size="15px" color="#ccc">
                            mdi-food-fork-drink
                          </v-icon>

                          <span class="f-size-12" style="padding-left: 5px">
                            Meal provided
                          </span>
                        </div> -->
                      </div>

                      <div class="mt-3">
                        <div class="d-flex position-relative">
                          <hr
                            v-if="data.Flight1.length - 1 !== index"
                            style="
                              width: 100%;
                              position: absolute;
                              margin-top: 8px !important;
                              margin-bottom: 0px !important;
                            "
                          />
                          <div
                            class="d-flex justify-center"
                            v-if="data.Flight1.length - 1 !== index"
                            style="
                              width: 180px;
                              background: antiquewhite;
                              border-radius: 18px;
                              z-index: 1;
                              margin: auto;
                            "
                          >
                            <div class="d-flex align-center">
                              <span class="f-size-10"
                                >{{ data.layoverTimes1[index] }} Layover
                                Time</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-card>
              </div>

              <div class="mt-2">
                <v-card>
                  <div
                    class="row d-flex justify-content-between align-center pt-2 pb-2 m-auto headfrom"
                    style="background-color: #edf6fd"
                  >
                    <div class="col-md-2 col-4">
                      <h5 class="m-0 font-change-14">
                        {{ getdepcode(data.Flight2) }} -

                        {{ getarrcode(data.Flight2) }}
                      </h5>
                    </div>
                    <div class="col-md-3 col-5 text-lg-start text-center">
                      <h6 class="m-0 font-change-11">
                        {{ depdate(data.Flight2) }}
                      </h6>
                    </div>
                    <div class="col-md-2 col-3 text-lg-start text-end">
                      <span class="stopsText">{{
                        getStop(data.Flight2) > 0
                          ? getStop(data.Flight2) +
                            " " +
                            $t("flightInfoContent.contents.stops")
                          : $t("flightInfoContent.contents.nonStop")
                      }}</span>
                    </div>
                    <div class="col-md-4 col-10 text-sm-start">
                      <span class="f-size-14 font-change-11"
                        >{{ $t("flightInfoContent.contents.tripDuration") }}
                        {{ data.Flight2JourneyTime.Time }}</span
                      >
                    </div>
                    <div
                      class="col-md-2 col-2 d-md-none d-flex flex-md-column justify-md-start class-box"
                      :class="{ 'd-none': !data.cabinClass }"
                    >
                      <div class="d-flex align-center class-type">
                        <v-icon size="15px" color="#ccc">
                          mdi-seat-passenger
                        </v-icon>

                        <span class="f-size-12">
                          {{ data.cabinClass }}
                        </span>
                      </div>
                    </div>
                    <!-- <div class="col-md-1 p-0 col-2">
            <a href="/search" class="text-decoration-none f-size-14 font-change-11">Change</a>
          </div> -->
                  </div>

                  <div class="px-2" style="max-width: 100%">
                    <div
                      class="row mt-3 mb-2"
                      v-for="(data1, index) of data.Flight2"
                      :key="index"
                    >
                      <div class="col-md-3 col-3 mb-3 mb-md-0">
                        <div class="row ms-sm-1 ms-0 w-100">
                          <div class="col-md-4 col-12 airline-logo">
                            <v-img
                              :src="data1.MarketingCarrier.logo"
                              width="30px"
                            ></v-img>
                          </div>
                          <div class="col-md-8 col-12 airline-profile">
                            <p class="f-size-13 airline-name m-0">
                              {{ data1.MarketingCarrier.Name }}

                              <br />
                              <span class="f-size-12 airline-id">
                                {{ data1.MarketingCarrier.AirlineID }} -
                                {{ data1.MarketingCarrier.FlightNumber }}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-7 col-9">
                        <div class="row d-flex">
                          <div class="col-sm-3 p-0">
                            <span class="f-size-10 airline-date">{{
                              getdated(data1.Departure.Date)
                            }}</span>
                            <span
                              class="f-size-13 airline-time"
                              style="margin-left: 5px"
                              >{{ getflightdep(data1) }}</span
                            >
                          </div>
                          <div
                            class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0"
                            style="margin-bottom: 4px"
                          >
                            <span class="fw-500"
                              >{{ data1.Departure.AirportCode }} </span
                            >, {{ data1.Departure.AirportName }}
                          </div>
                        </div>

                        <div class="row d-flex">
                          <div class="col-sm-3 p-0">
                            <span class="f-size-10 airline-date">{{
                              getdated1(data1.Arrival.Date)
                            }}</span>
                            <span
                              class="f-size-13 airline-time"
                              style="margin-left: 5px"
                              >{{ getflightarr(data1) }}</span
                            >
                          </div>

                          <div
                            class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0"
                            style="margin-bottom: 4px"
                          >
                            <span class="fw-500">{{
                              data1.Arrival.AirportCode
                            }}</span>
                            , {{ data1.Arrival.AirportName }}
                          </div>
                        </div>
                      </div>

                      <div
                        class="col-md-2 col-12 d-md-flex d-none flex-md-column justify-md-start class-box"
                        :class="{ 'd-none': !data.cabinClass }"
                      >
                        <div class="d-flex align-center class-type">
                          <v-icon size="15px" color="#ccc">
                            mdi-seat-passenger
                          </v-icon>

                          <span class="f-size-12" style="padding-left: 5px">
                            {{ data.cabinClass }}
                          </span>
                        </div>

                        <!-- <div class="d-flex ms-md-0 ms-2 class-type">
                          <v-icon size="15px" color="#ccc">
                            mdi-food-fork-drink
                          </v-icon>

                          <span class="f-size-12" style="padding-left: 5px">
                            Meal provided
                          </span>
                        </div> -->
                      </div>

                      <div class="mt-3">
                        <div class="d-flex position-relative">
                          <hr
                            v-if="data.Flight2.length - 1 !== index"
                            style="
                              width: 100%;
                              position: absolute;
                              margin-top: 8px !important;
                              margin-bottom: 0px !important;
                            "
                          />
                          <div
                            class="d-flex justify-center"
                            v-if="data.Flight2.length - 1 !== index"
                            style="
                              width: 180px;
                              background: antiquewhite;
                              border-radius: 18px;
                              z-index: 1;
                              margin: auto;
                            "
                          >
                            <div class="d-flex align-center">
                              <span class="f-size-10"
                                >{{ data.layoverTimes2[index] }} Layover
                                Time</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-card>
              </div>

              <div class="mt-2" v-if="data.Flight3 ? true : false">
                <v-card>
                  <div
                    class="row d-flex justify-content-between align-center pt-2 pb-2 m-auto headfrom"
                    style="background-color: #edf6fd"
                  >
                    <div class="col-md-2 col-4">
                      <h5 class="m-0 font-change-14">
                        {{ getdepcode(data.Flight3) }} -

                        {{ getarrcode(data.Flight3) }}
                      </h5>
                    </div>
                    <div class="col-md-3 col-5 text-lg-start text-center">
                      <h6 class="m-0 font-change-11">
                        {{ depdate(data.Flight3) }}
                      </h6>
                    </div>
                    <div class="col-md-2 col-3 text-lg-start text-end">
                      <span class="stopsText">{{
                        getStop(data.Flight3) > 0
                          ? getStop(data.Flight3) +
                            " " +
                            $t("flightInfoContent.contents.stops")
                          : $t("flightInfoContent.contents.nonStop")
                      }}</span>
                    </div>
                    <div class="col-md-4 col-10 text-sm-start">
                      <span class="f-size-14 font-change-11"
                        >{{ $t("flightInfoContent.contents.tripDuration") }}
                        {{ data.Flight3JourneyTime.Time }}</span
                      >
                    </div>
                    <div
                      class="col-md-2 col-2 d-md-none d-flex flex-md-column justify-md-start class-box"
                      :class="{ 'd-none': !data.cabinClass }"
                    >
                      <div class="d-flex align-center class-type">
                        <v-icon size="15px" color="#ccc">
                          mdi-seat-passenger
                        </v-icon>

                        <span class="f-size-12">
                          {{ data.cabinClass }}
                        </span>
                      </div>
                    </div>
                    <!-- <div class="col-md-1 p-0 col-2">
            <a href="/search" class="text-decoration-none f-size-14 font-change-11">Change</a>
          </div> -->
                  </div>

                  <div class="px-2" style="max-width: 100%">
                    <div
                      class="row mt-3 mb-2"
                      v-for="(data1, index) of data.Flight3"
                      :key="index"
                    >
                      <div class="col-md-3 col-3 mb-3 mb-md-0">
                        <div class="row ms-sm-1 ms-0 w-100">
                          <div class="col-md-4 col-12 airline-logo">
                            <v-img
                              :src="data1.MarketingCarrier.logo"
                              width="30px"
                            ></v-img>
                          </div>
                          <div class="col-md-8 col-12 airline-profile">
                            <p class="f-size-13 airline-name m-0">
                              {{ data1.MarketingCarrier.Name }}

                              <br />
                              <span class="f-size-12 airline-id">
                                {{ data1.MarketingCarrier.AirlineID }} -
                                {{ data1.MarketingCarrier.FlightNumber }}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-7 col-9">
                        <div class="row d-flex">
                          <div class="col-sm-3 p-0">
                            <span class="f-size-10 airline-date">{{
                              getdated(data1.Departure.Date)
                            }}</span>
                            <span
                              class="f-size-13 airline-time"
                              style="margin-left: 5px"
                              >{{ getflightdep(data1) }}</span
                            >
                          </div>
                          <div
                            class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0"
                            style="margin-bottom: 4px"
                          >
                            <span class="fw-500"
                              >{{ data1.Departure.AirportCode }} </span
                            >, {{ data1.Departure.AirportName }}
                          </div>
                        </div>

                        <div class="row d-flex">
                          <div class="col-sm-3 p-0">
                            <span class="f-size-10 airline-date">{{
                              getdated1(data1.Arrival.Date)
                            }}</span>
                            <span
                              class="f-size-13 airline-time"
                              style="margin-left: 5px"
                              >{{ getflightarr(data1) }}</span
                            >
                          </div>

                          <div
                            class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0"
                            style="margin-bottom: 4px"
                          >
                            <span class="fw-500">{{
                              data1.Arrival.AirportCode
                            }}</span>
                            , {{ data1.Arrival.AirportName }}
                          </div>
                        </div>
                      </div>

                      <div
                        class="col-md-2 col-12 d-md-flex d-none flex-md-column justify-md-start class-box"
                        :class="{ 'd-none': !data.cabinClass }"
                      >
                        <div class="d-flex align-center class-type">
                          <v-icon size="15px" color="#ccc">
                            mdi-seat-passenger
                          </v-icon>

                          <span class="f-size-12" style="padding-left: 5px">
                            {{ data.cabinClass }}
                          </span>
                        </div>

                        <!-- <div class="d-flex ms-md-0 ms-2 class-type">
                          <v-icon size="15px" color="#ccc">
                            mdi-food-fork-drink
                          </v-icon>

                          <span class="f-size-12" style="padding-left: 5px">
                            Meal provided
                          </span>
                        </div> -->
                      </div>

                      <div class="mt-3">
                        <div class="d-flex position-relative">
                          <hr
                            v-if="data.Flight3.length - 1 !== index"
                            style="
                              width: 100%;
                              position: absolute;
                              margin-top: 8px !important;
                              margin-bottom: 0px !important;
                            "
                          />
                          <div
                            class="d-flex justify-center"
                            v-if="data.Flight3.length - 1 !== index"
                            style="
                              width: 180px;
                              background: antiquewhite;
                              border-radius: 18px;
                              z-index: 1;
                              margin: auto;
                            "
                          >
                            <div class="d-flex align-center">
                              <span class="f-size-10"
                                >{{ data.layoverTimes3[index] }} Layover
                                Time</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-card>
              </div>

              <div class="mt-2" v-if="data.Flight4 ? true : false">
                <v-card>
                  <div
                    class="row d-flex justify-content-between align-center pt-2 pb-2 m-auto headfrom"
                    style="background-color: #edf6fd"
                  >
                    <div class="col-md-2 col-4">
                      <h5 class="m-0 font-change-14">
                        {{ getdepcode(data.Flight4) }} -

                        {{ getarrcode(data.Flight4) }}
                      </h5>
                    </div>
                    <div class="col-md-3 col-5 text-lg-start text-center">
                      <h6 class="m-0 font-change-11">
                        {{ depdate(data.Flight4) }}
                      </h6>
                    </div>
                    <div class="col-md-2 col-3 text-lg-start text-end">
                      <span class="stopsText">{{
                        getStop(data.Flight4) > 0
                          ? getStop(data.Flight4) +
                            " " +
                            $t("flightInfoContent.contents.stops")
                          : $t("flightInfoContent.contents.nonStop")
                      }}</span>
                    </div>
                    <div class="col-md-4 col-10 text-sm-start">
                      <span class="f-size-14 font-change-11"
                        >{{ $t("flightInfoContent.contents.tripDuration") }}
                        {{ data.Flight4JourneyTime.Time }}</span
                      >
                    </div>
                    <div
                      class="col-md-2 col-2 d-md-none d-flex flex-md-column justify-md-start class-box"
                      :class="{ 'd-none': !data.cabinClass }"
                    >
                      <div class="d-flex align-center class-type">
                        <v-icon size="15px" color="#ccc">
                          mdi-seat-passenger
                        </v-icon>

                        <span class="f-size-12">
                          {{ data.cabinClass }}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div class="px-2" style="max-width: 100%">
                    <div
                      class="row mt-3 mb-2"
                      v-for="(data1, index) of data.Flight4"
                      :key="index"
                    >
                      <div class="col-md-3 col-3 mb-3 mb-md-0">
                        <div class="row ms-sm-1 ms-0 w-100">
                          <div class="col-md-4 col-12 airline-logo">
                            <v-img
                              :src="data1.MarketingCarrier.logo"
                              width="30px"
                            ></v-img>
                          </div>
                          <div class="col-md-8 col-12 airline-profile">
                            <p class="f-size-13 airline-name m-0">
                              {{ data1.MarketingCarrier.Name }}

                              <br />
                              <span class="f-size-12 airline-id">
                                {{ data1.MarketingCarrier.AirlineID }} -
                                {{ data1.MarketingCarrier.FlightNumber }}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-7 col-9">
                        <div class="row d-flex">
                          <div class="col-sm-3 p-0">
                            <span class="f-size-10 airline-date">{{
                              getdated(data1.Departure.Date)
                            }}</span>
                            <span
                              class="f-size-13 airline-time"
                              style="margin-left: 5px"
                              >{{ getflightdep(data1) }}</span
                            >
                          </div>
                          <div
                            class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0"
                            style="margin-bottom: 4px"
                          >
                            <span class="fw-500"
                              >{{ data1.Departure.AirportCode }} </span
                            >, {{ data1.Departure.AirportName }}
                          </div>
                        </div>

                        <div class="row d-flex">
                          <div class="col-sm-3 p-0">
                            <span class="f-size-10 airline-date">{{
                              getdated1(data1.Arrival.Date)
                            }}</span>
                            <span
                              class="f-size-13 airline-time"
                              style="margin-left: 5px"
                              >{{ getflightarr(data1) }}</span
                            >
                          </div>

                          <div
                            class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0"
                            style="margin-bottom: 4px"
                          >
                            <span class="fw-500">{{
                              data1.Arrival.AirportCode
                            }}</span>
                            , {{ data1.Arrival.AirportName }}
                          </div>
                        </div>
                      </div>

                      <div
                        class="col-md-2 col-12 d-md-flex d-none flex-md-column justify-md-start class-box"
                        :class="{ 'd-none': !data.cabinClass }"
                      >
                        <div class="d-flex align-center class-type">
                          <v-icon size="15px" color="#ccc">
                            mdi-seat-passenger
                          </v-icon>

                          <span class="f-size-12" style="padding-left: 5px">
                            {{ data.cabinClass }}
                          </span>
                        </div>

                        <!-- <div class="d-flex ms-md-0 ms-2 class-type">
                          <v-icon size="15px" color="#ccc">
                            mdi-food-fork-drink
                          </v-icon>

                          <span class="f-size-12" style="padding-left: 5px">
                            Meal provided
                          </span>
                        </div> -->
                      </div>

                      <div class="mt-3">
                        <div class="d-flex position-relative">
                          <hr
                            v-if="data.Flight4.length - 1 !== index"
                            style="
                              width: 100%;
                              position: absolute;
                              margin-top: 8px !important;
                              margin-bottom: 0px !important;
                            "
                          />
                          <div
                            class="d-flex justify-center"
                            v-if="data.Flight4.length - 1 !== index"
                            style="
                              width: 180px;
                              background: antiquewhite;
                              border-radius: 18px;
                              z-index: 1;
                              margin: auto;
                            "
                          >
                            <div class="d-flex align-center">
                              <span class="f-size-10"
                                >{{ data.layoverTimes4[index] }} Layover
                                Time</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-card>
              </div>
            </v-card>
          </div>

          <div class="my-4" style="margin: 10px 12px">
            <div class="row">
              <div class="col-md-12 p-0">
                <v-card>
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <h3 class="pt-3 ps-3">
                        {{ $t("flightInfoContent.headings.heading2") }}
                      </h3>
                    </div>
                  </div>

                  <div class="py-2 px-3">
                    <div
                      class="row mt-2"
                      v-for="(data, index) of adultData"
                      :key="index"
                    >
                      <h6>Adult {{ index + 1 }}</h6>

                      <div class="row pe-0">
                        <div class="col-lg-2 pe-0">
                          <v-select
                            :label="
                              this.$t('flightInfoContent.labels.salutation')
                            "
                            :items="['Mr', 'Miss', 'Mrs', 'Ms']"
                            v-model="data.salutation"
                            variant="outlined"
                          ></v-select>
                        </div>
                        <div class="col-lg-3 pe-0">
                          <v-text-field
                            :label="
                              this.$t('flightInfoContent.labels.firstName')
                            "
                            variant="outlined"
                            v-model="data.fname"
                            :rules="firstName"
                          ></v-text-field>
                        </div>

                        <div class="col-lg-3 pe-0">
                          <v-text-field
                            :label="
                              this.$t('flightInfoContent.labels.middleName')
                            "
                            variant="outlined"
                            v-model="data.middle"
                          ></v-text-field>
                        </div>

                        <div class="col-lg-3 pe-0">
                          <v-text-field
                            :label="
                              this.$t('flightInfoContent.labels.lastName')
                            "
                            variant="outlined"
                            v-model="data.last"
                            :rules="lastName"
                          ></v-text-field>
                        </div>
                      </div>

                      <div class="row pe-0">
                        <div class="col-lg-2 d-flex align-end pe-0">
                          <v-select
                            v-model="data.gender"
                            :label="this.$t('flightInfoContent.labels.gender')"
                            :items="['Male', 'Female']"
                            variant="outlined"
                            :rules="[(v) => !!v || 'This field is required']"
                          ></v-select>
                        </div>

                        <div class="col-lg-6 datePickerSection pe-0">
                          <div>
                            <p
                              class="m-0 mb-1 d-flex flex-column"
                              style="font-size: 12px"
                            >
                              {{ $t("flightInfoContent.labels.dateofBirth") }}
                            </p>
                          </div>
                          <div class="d-flex">
                            <v-text-field
                              v-model="data.dateDob.year"
                              class="yearPick"
                              :label="this.$t('flightInfoContent.labels.year')"
                              placeholder="Year"
                              :rules="yearRules(data.Type, index)"
                              maxlength="4"
                              variant="outlined"
                              @keypress="filter(event)"
                              @input="inputHandle(data, index)"
                              ref="yearInput"
                            ></v-text-field>

                            <v-select
                              class="monthPick"
                              :label="this.$t('flightInfoContent.labels.month')"
                              v-model="data.dateDob.month"
                              :items="data.isYearValid ? monthListOriginal : []"
                              variant="outlined"
                              :rules="monthRules(data.Type, index)"
                              @click="inputChange(data, index)"
                            ></v-select>

                            <v-text-field
                              v-model="data.dateDob.date"
                              class="datePick"
                              :label="this.$t('flightInfoContent.labels.date')"
                              :rules="dateRules(data, index)"
                              maxlength="2"
                              variant="outlined"
                              @keypress="filter(event)"
                            ></v-text-field>
                          </div>
                        </div>

                        <!-- <div class="col-md-3">
                          <div class="col-md-12">
                            <span
                              :class="
                                data.clicked1
                                  ? 'p-float-label-1'
                                  : 'p-float-label-2'
                              "
                            >
                              <label
                                :class="
                                  data.clicked1 ? 'dob-label-top' : 'dob-label'
                                "
                                >Date of Birth</label
                              >
                              <VueDatePicker
                                v-model="data.dateOfBirth"
                                ref="dpRef1"
                                no-today
                                :six-weeks="true"
                                auto-apply
                                :start-date="
                                  new Date(
                                    new Date().getFullYear() - 12,
                                    new Date().getMonth(),
                                    new Date().getDate()
                                  )
                                "
                                :max-date="
                                  new Date(
                                    new Date().getFullYear() - 12,
                                    new Date().getMonth(),
                                    new Date().getDate()
                                  )
                                "
                                :min-date="
                                  new Date(
                                    new Date().getFullYear() - 100,
                                    new Date().getMonth(),
                                    new Date().getDate()
                                  )
                                "
                                :format="dateFormat"
                                :hide-navigation="['time']"
                                @update:modelValue="adultDob()"
                                class="px-0"
                              />
                              <span class="p-error">Dep. Date is required</span>
                            </span>
                            <p
                              class="m-0 mb-1 d-flex flex-column"
                              style="font-size: 12px"
                            >
                              {{ $t('flightInfoContent.labels.dateofBirth') }}
                            </p>
                          </div>
                        </div> -->

                        <div>
                          <v-card class="ffb-cart" v-if="data.ffbnumber">
                            <div align="end">
                              <v-icon
                                color="primary"
                                @click="data.ffbnumber = false"
                              >
                                mdi-close-circle
                              </v-icon>
                            </div>

                            <h6>Frequent Flyer Program</h6>

                            <div class="row mt-4">
                              <div class="col-md-3">
                                <v-select
                                  label="Frequent Flyer Program"
                                  rounted="5"
                                  v-model="air"
                                  :items="[
                                    'Aeroplan-Air canada',
                                    'Flying Returns-Air india',
                                    'Air Points-Air America',
                                    'Phonix-Air china',
                                  ]"
                                  variant="outlined"
                                ></v-select>
                              </div>
                              <div class="col-md-3">
                                <v-text-field
                                  label="FFP Number"
                                  variant="outlined"
                                  v-model="ffb"
                                ></v-text-field>
                              </div>
                              <div class="col-md-6" align="end">
                                <v-btn
                                  variant="outlined"
                                  color="primary"
                                  @click="reset()"
                                >
                                  Reset
                                </v-btn>
                              </div>
                            </div>
                          </v-card>

                          <v-card class="ffb-cart" v-if="data.TSAnumber">
                            <div align="end">
                              <v-icon
                                color="primary"
                                @click="data.TSAnumber = false"
                              >
                                mdi-close-circle
                              </v-icon>
                            </div>

                            <h6>Transport Security Administration</h6>
                            <div class="row mt-4 gy-3">
                              <div class="col-lg-3">
                                <v-text-field
                                  label="Last name as per passport"
                                  variant="outlined"
                                ></v-text-field>
                              </div>
                              <div class="col-lg-3">
                                <v-text-field
                                  label="First name as per passport"
                                  variant="outlined"
                                ></v-text-field>
                              </div>
                              <div class="col-lg-3">
                                <v-text-field
                                  label="2nd and additional given names"
                                  variant="outlined"
                                ></v-text-field>
                              </div>
                              <div class="col-lg-3">
                                <v-text-field
                                  label="Date of Birth"
                                  type="date"
                                  variant="outlined"
                                ></v-text-field>
                              </div>
                            </div>
                            <p class="redress">
                              Redress number and known traveller number
                              <span>
                                <v-icon @click="Redressnumber = !Redressnumber"
                                  >mdi-menu-down</v-icon
                                >
                              </span>
                            </p>

                            <div class="row mt-4 gy-3">
                              <div class="col-md-3">
                                <v-text-field
                                  v-if="Redressnumber"
                                  label="Redress Number"
                                  v-model="Redress"
                                  variant="outlined"
                                ></v-text-field>
                              </div>
                              <div class="col-md-3">
                                <v-text-field
                                  v-if="Redressnumber"
                                  label="Known Traveler Number"
                                  v-model="Traveler"
                                  variant="outlined"
                                ></v-text-field>
                              </div>
                              <div class="col-md-6" align="end">
                                <v-btn
                                  variant="outlined"
                                  color="primary"
                                  @click="reset1()"
                                >
                                  Reset
                                </v-btn>
                              </div>
                            </div>
                          </v-card>

                          <v-card class="ffb-cart p-4" v-if="data.Apinumber">
                            <h6>Advance Passenger Information System</h6>
                            <h6>Primary Data of the Travel Document</h6>
                            <div class="row mt-3 gy-3">
                              <div class="col-lg-3">
                                <v-text-field
                                  label="Last name as per passport"
                                  variant="outlined"
                                ></v-text-field>
                              </div>
                              <div class="col-lg-3">
                                <v-text-field
                                  label="First name as per passport"
                                  variant="outlined"
                                ></v-text-field>
                              </div>
                              <div class="col-lg-3">
                                <v-text-field
                                  label="2nd and additional given names    "
                                  variant="outlined"
                                ></v-text-field>
                              </div>
                              <div class="col-lg-2">
                                <v-text-field
                                  label="Date Of Birth"
                                  type="date"
                                  variant="outlined"
                                ></v-text-field>
                              </div>
                            </div>

                            <div class="row mt-4 gy-3">
                              <div class="col-3-lg">
                                <v-select
                                  label="Document Type"
                                  :items="[
                                    'Aeroplan-Air canada',
                                    'Flying Returns-Air india',
                                    'Air Points-Air America',
                                    'Phonix-Air china',
                                  ]"
                                  variant="outlined"
                                ></v-select>
                              </div>
                              <div class="col-3-lg">
                                <v-text-field
                                  label="Document Number"
                                  variant="outlined"
                                ></v-text-field>
                              </div>
                              <div class="col-3-lg">
                                <v-select
                                  label="Issuance Country"
                                  rounted="5"
                                  :items="[
                                    'Aeroplan-Air canada',
                                    'Flying Returns-Air india',
                                    'Air Points-Air America',
                                    'Phonix-Air china',
                                  ]"
                                  variant="outlined"
                                ></v-select>
                              </div>
                              <div class="col-3-lg">
                                <v-select
                                  label="Nationality"
                                  :items="[
                                    'Aeroplan-Air canada',
                                    'Flying Returns-Air india',
                                    'Air Points-Air America',
                                    'Phonix-Air china',
                                  ]"
                                  variant="outlined"
                                ></v-select>
                              </div>
                            </div>

                            <div class="row mt-2 gy-3">
                              <div class="col-2-lg">
                                <v-text-field
                                  label="Issue Date"
                                  type="date"
                                  variant="outlined"
                                ></v-text-field>
                              </div>
                              <div class="col-2-lg">
                                <v-text-field
                                  label="Expiration"
                                  type="date"
                                  variant="outlined"
                                ></v-text-field>
                              </div>
                            </div>
                            <p
                              style="color: blue; cursor: pointer"
                              @click="apivalue = !apivalue"
                            >
                              Passenger contact address (DOCA)
                              <span>
                                <v-icon>mdi-menu-down</v-icon>
                              </span>
                            </p>

                            <div class="row mt-2 gy-3" v-if="apivalue">
                              <div class="col-md-2">
                                <p>Type of address</p>
                              </div>
                              <div class="col-md-10">
                                <v-radio-group
                                  inline
                                  color="primary"
                                  v-model="value"
                                >
                                  <v-radio
                                    label="Destination"
                                    value="1"
                                  ></v-radio>
                                  <v-radio
                                    label="Residence"
                                    value="2"
                                  ></v-radio>
                                </v-radio-group>
                              </div>
                            </div>

                            <div class="row mt-4 gy-3" v-if="apivalue">
                              <div class="col-md-2">
                                <v-text-field
                                  label="Address Details"
                                  variant="outlined"
                                ></v-text-field>
                              </div>
                              <div class="col-md-2">
                                <v-text-field
                                  label="ZIP/Postal code"
                                  variant="outlined"
                                ></v-text-field>
                              </div>
                              <div class="col-md-3">
                                <v-select
                                  label="Country"
                                  :items="[
                                    'Aeroplan-Air canada',
                                    'Flying Returns-Air india',
                                    'Air Points-Air America',
                                    'Phonix-Air china',
                                  ]"
                                  variant="outlined"
                                ></v-select>
                              </div>
                              <div class="col-md-3">
                                <v-select
                                  label="State/province"
                                  :items="[
                                    'Aeroplan-Air canada',
                                    'Flying Returns-Air india',
                                    'Air Points-Air America',
                                    'Phonix-Air china',
                                  ]"
                                  variant="outlined"
                                ></v-select>
                              </div>
                              <div class="col-md-2">
                                <v-text-field
                                  label="City"
                                  variant="outlined"
                                ></v-text-field>
                              </div>
                            </div>
                            <div class="col-12 mt-2" align="end">
                              <v-btn variant="outlined" color="primary">
                                Reset
                              </v-btn>
                            </div>
                          </v-card>
                        </div>
                      </div>
                    </div>

                    <div
                      class="row mt-2"
                      v-for="(data, index) of childData"
                      :key="data"
                    >
                      <h6>Children {{ index + 1 }}</h6>

                      <div class="row">
                        <div class="col-lg-2">
                          <v-select
                            :label="
                              this.$t('flightInfoContent.labels.salutation')
                            "
                            :items="['Mstr', 'Miss']"
                            v-model="data.salutation"
                            variant="outlined"
                          ></v-select>
                        </div>

                        <div class="col-lg-3">
                          <v-text-field
                            :label="
                              this.$t('flightInfoContent.labels.firstName')
                            "
                            variant="outlined"
                            v-model="data.fname"
                            :rules="firstName"
                          ></v-text-field>
                        </div>

                        <div class="col-lg-3">
                          <v-text-field
                            :label="
                              this.$t('flightInfoContent.labels.middleName')
                            "
                            variant="outlined"
                            v-model="data.middle"
                          ></v-text-field>
                        </div>

                        <div class="col-lg-3">
                          <v-text-field
                            :label="
                              this.$t('flightInfoContent.labels.lastName')
                            "
                            variant="outlined"
                            v-model="data.last"
                            :rules="lastName"
                          ></v-text-field>
                        </div>

                        <!-- <div class="col-lg-2">
                   <v-select :label="this.$t('flightInfoContent.labels.gender')" :items="['Male', 'Female']" variant="outlined"
                     :rules="[(v) => !!v || 'this field is required']" v-model="data.gender"></v-select>
                 </div> -->
                      </div>

                      <div class="row">
                        <div class="col-lg-2 d-flex align-end">
                          <v-select
                            :label="this.$t('flightInfoContent.labels.gender')"
                            :items="['Male', 'Female']"
                            variant="outlined"
                            :rules="[(v) => !!v || 'this field is required']"
                            v-model="data.gender"
                          ></v-select>
                        </div>

                        <div class="col-lg-6 datePickerSection">
                          <div>
                            <p
                              class="m-0 mb-1 d-flex flex-column"
                              style="font-size: 12px"
                            >
                              {{ $t("flightInfoContent.labels.dateofBirth") }}
                            </p>
                          </div>
                          <div class="d-flex">
                            <v-text-field
                              v-model="data.dateDob.year"
                              class="yearPick"
                              :label="this.$t('flightInfoContent.labels.year')"
                              placeholder="Year"
                              :rules="yearRules(data.Type, index)"
                              maxlength="4"
                              variant="outlined"
                              @keypress="filter(event)"
                              @input="inputHandle(data, index)"
                            ></v-text-field>

                            <v-select
                              class="monthPick"
                              :label="this.$t('flightInfoContent.labels.month')"
                              v-model="data.dateDob.month"
                              :items="data.isYearValid ? monthListOriginal : []"
                              variant="outlined"
                              :rules="monthRules(data.Type, index)"
                              @click="inputChange(data, index)"
                            ></v-select>

                            <v-text-field
                              v-model="data.dateDob.date"
                              class="datePick"
                              :label="this.$t('flightInfoContent.labels.date')"
                              placeholder="Date"
                              :rules="dateRules(data, index)"
                              maxlength="2"
                              variant="outlined"
                              @keypress="filter(event)"
                            ></v-text-field>
                          </div>
                        </div>

                        <!-- <div class="col-md-6 mt-2">
                   <v-btn-toggle class="d-flex align-center">
                     <v-btn append-icon="mdi-menu-down" variant="outlined" rounded="2" class="ffb-button"
                       @click="ffbdata(data, index)">
                       FFp
                       <v-tooltip activator="parent" location="top" width="200px">
                         Please select an airline/frequent
                       </v-tooltip>
                     </v-btn>
                     <v-btn append-icon="mdi-menu-down" variant="outlined" rounded="2" class="ffb-button"
                       @click="tsadata(index)">
                       TSA
                       <v-tooltip activator="parent" location="top" width="200px">
                         Please select an airline/frequent flyer program
                       </v-tooltip>
                     </v-btn>
                     <v-btn append-icon="mdi-menu-down" variant="outlined" rounded="2" class="ffb-button"
                       @click="apidata(index)">
                       APIS
                       <v-tooltip activator="parent" location="top" width="200px">
                         the validating carrier are being displayed.
                       </v-tooltip>
                     </v-btn>
                   </v-btn-toggle>
                 </div> -->
                      </div>

                      <div>
                        <v-card class="ffb-cart" v-if="data.ffbnumber">
                          <div align="end">
                            <v-icon
                              color="primary"
                              @click="data.ffbnumber = false"
                            >
                              mdi-close-circle
                            </v-icon>
                          </div>

                          <h6>Frequent Flyer Program</h6>

                          <div class="row mt-4">
                            <div class="col-md-3">
                              <v-select
                                label="Frequent Flyer Program"
                                rounted="5"
                                v-model="air"
                                :items="[
                                  'Aeroplan-Air canada',
                                  'Flying Returns-Air india',
                                  'Air Points-Air America',
                                  'Phonix-Air china',
                                ]"
                                variant="outlined"
                              ></v-select>
                            </div>
                            <div class="col-md-3">
                              <v-text-field
                                label="FFP Number"
                                variant="outlined"
                                v-model="ffb"
                              ></v-text-field>
                            </div>
                            <div class="col-md-6" align="end">
                              <v-btn
                                variant="outlined"
                                color="primary"
                                @click="reset()"
                              >
                                Reset
                              </v-btn>
                            </div>
                          </div>
                        </v-card>

                        <v-card class="ffb-cart" v-if="data.TSAnumber">
                          <div align="end">
                            <v-icon
                              color="primary"
                              @click="data.TSAnumber = false"
                            >
                              mdi-close-circle
                            </v-icon>
                          </div>

                          <h6>Transport Security Administration</h6>
                          <div class="row mt-4 gy-3">
                            <div class="col-lg-3">
                              <v-text-field
                                label="Last name as per passport"
                                variant="outlined"
                              ></v-text-field>
                            </div>
                            <div class="col-lg-3">
                              <v-text-field
                                label="First name as per passport"
                                variant="outlined"
                              ></v-text-field>
                            </div>
                            <div class="col-lg-3">
                              <v-text-field
                                label="2nd and additional given names"
                                variant="outlined"
                              ></v-text-field>
                            </div>
                            <div class="col-lg-3">
                              <v-text-field
                                label="Date of Birth"
                                type="date"
                                variant="outlined"
                              ></v-text-field>
                            </div>
                          </div>
                          <p class="redress">
                            Redress number and known traveller number
                            <span>
                              <v-icon @click="Redressnumber = !Redressnumber"
                                >mdi-menu-down</v-icon
                              >
                            </span>
                          </p>

                          <div class="row mt-4 gy-3">
                            <div class="col-md-3">
                              <v-text-field
                                v-if="Redressnumber"
                                label="Redress Number"
                                v-model="Redress"
                                variant="outlined"
                              ></v-text-field>
                            </div>
                            <div class="col-md-3">
                              <v-text-field
                                v-if="Redressnumber"
                                label="Known Traveler Number"
                                v-model="Traveler"
                                variant="outlined"
                              ></v-text-field>
                            </div>
                            <div class="col-md-6" align="end">
                              <v-btn
                                variant="outlined"
                                color="primary"
                                @click="reset1()"
                              >
                                Reset
                              </v-btn>
                            </div>
                          </div>
                        </v-card>

                        <v-card class="ffb-cart p-4" v-if="data.Apinumber">
                          <h6>Advance Passenger Information System</h6>
                          <h6>Primary Data of the Travel Document</h6>
                          <div class="row mt-3 gy-3">
                            <div class="col-lg-3">
                              <v-text-field
                                label="Last name as per passport"
                                variant="outlined"
                              ></v-text-field>
                            </div>
                            <div class="col-lg-3">
                              <v-text-field
                                label="First name as per passport"
                                variant="outlined"
                              ></v-text-field>
                            </div>
                            <div class="col-lg-3">
                              <v-text-field
                                label="2nd and additional given names    "
                                variant="outlined"
                              ></v-text-field>
                            </div>
                            <div class="col-lg-2">
                              <v-text-field
                                label="Date Of Birth"
                                type="date"
                                variant="outlined"
                              ></v-text-field>
                            </div>
                          </div>

                          <div class="row mt-4 gy-3">
                            <div class="col-3-lg">
                              <v-select
                                label="Document Type"
                                :items="[
                                  'Aeroplan-Air canada',
                                  'Flying Returns-Air india',
                                  'Air Points-Air America',
                                  'Phonix-Air china',
                                ]"
                                variant="outlined"
                              ></v-select>
                            </div>
                            <div class="col-3-lg">
                              <v-text-field
                                label="Document Number"
                                variant="outlined"
                              ></v-text-field>
                            </div>
                            <div class="col-3-lg">
                              <v-select
                                label="Issuance Country"
                                rounted="5"
                                :items="[
                                  'Aeroplan-Air canada',
                                  'Flying Returns-Air india',
                                  'Air Points-Air America',
                                  'Phonix-Air china',
                                ]"
                                variant="outlined"
                              ></v-select>
                            </div>
                            <div class="col-3-lg">
                              <v-select
                                label="Nationality"
                                :items="[
                                  'Aeroplan-Air canada',
                                  'Flying Returns-Air india',
                                  'Air Points-Air America',
                                  'Phonix-Air china',
                                ]"
                                variant="outlined"
                              ></v-select>
                            </div>
                          </div>

                          <div class="row mt-2 gy-3">
                            <div class="col-2-lg">
                              <v-text-field
                                label="Issue Date"
                                type="date"
                                variant="outlined"
                              ></v-text-field>
                            </div>
                            <div class="col-2-lg">
                              <v-text-field
                                label="Expiration"
                                type="date"
                                variant="outlined"
                              ></v-text-field>
                            </div>
                          </div>
                          <p
                            style="color: blue; cursor: pointer"
                            @click="apivalue = !apivalue"
                          >
                            Passenger contact address (DOCA)
                            <span>
                              <v-icon>mdi-menu-down</v-icon>
                            </span>
                          </p>

                          <div class="row mt-2 gy-3" v-if="apivalue">
                            <div class="col-md-2">
                              <p>Type of address</p>
                            </div>
                            <div class="col-md-10">
                              <v-radio-group
                                inline
                                color="primary"
                                v-model="value"
                              >
                                <v-radio
                                  label="Destination"
                                  value="1"
                                ></v-radio>
                                <v-radio label="Residence" value="2"></v-radio>
                              </v-radio-group>
                            </div>
                          </div>

                          <div class="row mt-4 gy-3" v-if="apivalue">
                            <div class="col-md-2">
                              <v-text-field
                                label="Address Details"
                                variant="outlined"
                              ></v-text-field>
                            </div>
                            <div class="col-md-2">
                              <v-text-field
                                label="ZIP/Postal code"
                                variant="outlined"
                              ></v-text-field>
                            </div>
                            <div class="col-md-3">
                              <v-select
                                label="Country"
                                :items="[
                                  'Aeroplan-Air canada',
                                  'Flying Returns-Air india',
                                  'Air Points-Air America',
                                  'Phonix-Air china',
                                ]"
                                variant="outlined"
                              ></v-select>
                            </div>
                            <div class="col-md-3">
                              <v-select
                                label="State/province"
                                :items="[
                                  'Aeroplan-Air canada',
                                  'Flying Returns-Air india',
                                  'Air Points-Air America',
                                  'Phonix-Air china',
                                ]"
                                variant="outlined"
                              ></v-select>
                            </div>
                            <div class="col-md-2">
                              <v-text-field
                                label="City"
                                variant="outlined"
                              ></v-text-field>
                            </div>
                          </div>
                          <div class="col-12 mt-2" align="end">
                            <v-btn variant="outlined" color="primary">
                              Reset
                            </v-btn>
                          </div>
                        </v-card>
                      </div>
                    </div>

                    <div
                      class="row mt-2"
                      v-for="(data, index) of infrantData"
                      :key="data"
                    >
                      <h6>Lap Infant {{ index + 1 }}</h6>

                      <div class="row">
                        <div class="col-lg-2">
                          <v-select
                            :label="
                              this.$t('flightInfoContent.labels.salutation')
                            "
                            :items="['Mstr', 'Miss']"
                            v-model="data.salutation"
                            variant="outlined"
                          ></v-select>
                        </div>

                        <div class="col-lg-3">
                          <v-text-field
                            :label="
                              this.$t('flightInfoContent.labels.firstName')
                            "
                            variant="outlined"
                            v-model="data.fname"
                            :rules="firstName"
                          ></v-text-field>
                        </div>

                        <div class="col-lg-3">
                          <v-text-field
                            :label="
                              this.$t('flightInfoContent.labels.middleName')
                            "
                            variant="outlined"
                            v-model="data.middle"
                          ></v-text-field>
                        </div>
                        <div class="col-lg-3">
                          <v-text-field
                            :label="
                              this.$t('flightInfoContent.labels.lastName')
                            "
                            variant="outlined"
                            v-model="data.last"
                            :rules="lastName"
                          ></v-text-field>
                        </div>

                        <!-- <div class="col-lg-2">
                   <v-select :label="this.$t('flightInfoContent.labels.gender')" :items="['Male', 'Female']" variant="outlined"
                     :rules="[(v) => !!v || 'this field is required']" v-model="data.gender"></v-select>
                 </div> -->
                      </div>

                      <div class="row">
                        <div class="col-lg-2 d-flex align-end">
                          <v-select
                            :label="this.$t('flightInfoContent.labels.gender')"
                            :items="['Male', 'Female']"
                            variant="outlined"
                            :rules="[(v) => !!v || 'this field is required']"
                            v-model="data.gender"
                          ></v-select>
                        </div>
                        <!-- <label>Date of Birth</label> -->

                        <div class="col-lg-6 datePickerSection">
                          <div>
                            <p
                              class="m-0 mb-1 d-flex flex-column"
                              style="font-size: 12px"
                            >
                              {{ $t("flightInfoContent.labels.dateofBirth") }}
                            </p>
                          </div>
                          <div class="d-flex">
                            <v-text-field
                              v-model="data.dateDob.year"
                              class="yearPick"
                              :label="this.$t('flightInfoContent.labels.year')"
                              placeholder="Year"
                              :rules="yearRules(data.Type, index)"
                              maxlength="4"
                              variant="outlined"
                              @keypress="filter(event)"
                              @input="inputHandle(data, index)"
                            ></v-text-field>

                            <v-select
                              class="monthPick"
                              :label="this.$t('flightInfoContent.labels.month')"
                              v-model="data.dateDob.month"
                              :items="data.isYearValid ? monthList : []"
                              variant="outlined"
                              :rules="monthRules(data.Type, index)"
                              @click="inputChange(data, index)"
                            ></v-select>

                            <v-text-field
                              v-model="data.dateDob.date"
                              class="datePick"
                              :label="this.$t('flightInfoContent.labels.date')"
                              placeholder="Date"
                              :rules="dateRules(data, index)"
                              maxlength="2"
                              variant="outlined"
                              @keypress="filter(event)"
                            ></v-text-field>
                          </div>
                        </div>

                        <!-- <div class="col-md-6 mt-2">
                   <v-btn-toggle class="d-flex align-center">
                     <v-btn append-icon="mdi-menu-down" variant="outlined" rounded="2" class="ffb-button"
                       @click="ffbdata(data, index)">
                       FFp
                       <v-tooltip activator="parent" location="top" width="200px">
                         Please select an airline/frequent
                       </v-tooltip>
                     </v-btn>
                     <v-btn append-icon="mdi-menu-down" variant="outlined" rounded="2" class="ffb-button"
                       @click="tsadata(index)">
                       TSA
                       <v-tooltip activator="parent" location="top" width="200px">
                         Please select an airline/frequent flyer program
                       </v-tooltip>
                     </v-btn>
                     <v-btn append-icon="mdi-menu-down" variant="outlined" rounded="2" class="ffb-button"
                       @click="apidata(index)">
                       APIS
                       <v-tooltip activator="parent" location="top" width="200px">
                         the validating carrier are being displayed.
                       </v-tooltip>
                     </v-btn>
                   </v-btn-toggle>
                 </div> -->
                      </div>

                      <div>
                        <v-card class="ffb-cart" v-if="data.ffbnumber">
                          <div align="end">
                            <v-icon
                              color="primary"
                              @click="data.ffbnumber = false"
                            >
                              mdi-close-circle
                            </v-icon>
                          </div>

                          <h6>Frequent Flyer Program</h6>

                          <div class="row mt-4">
                            <div class="col-md-3">
                              <v-select
                                label="Frequent Flyer Program"
                                rounted="5"
                                v-model="air"
                                :items="[
                                  'Aeroplan-Air canada',
                                  'Flying Returns-Air india',
                                  'Air Points-Air America',
                                  'Phonix-Air china',
                                ]"
                                variant="outlined"
                              ></v-select>
                            </div>
                            <div class="col-md-3">
                              <v-text-field
                                label="FFP Number"
                                variant="outlined"
                                v-model="ffb"
                              ></v-text-field>
                            </div>
                            <div class="col-md-6" align="end">
                              <v-btn
                                variant="outlined"
                                color="primary"
                                @click="reset()"
                              >
                                Reset
                              </v-btn>
                            </div>
                          </div>
                        </v-card>

                        <v-card class="ffb-cart" v-if="data.TSAnumber">
                          <div align="end">
                            <v-icon
                              color="primary"
                              @click="data.TSAnumber = false"
                            >
                              mdi-close-circle
                            </v-icon>
                          </div>

                          <h6>Transport Security Administration</h6>
                          <div class="row mt-4 gy-3">
                            <div class="col-lg-3">
                              <v-text-field
                                label="Last name as per passport"
                                variant="outlined"
                              ></v-text-field>
                            </div>
                            <div class="col-lg-3">
                              <v-text-field
                                label="First name as per passport"
                                variant="outlined"
                              ></v-text-field>
                            </div>
                            <div class="col-lg-3">
                              <v-text-field
                                label="2nd and additional given names"
                                variant="outlined"
                              ></v-text-field>
                            </div>
                            <div class="col-lg-3">
                              <v-text-field
                                label="Date of Birth"
                                type="date"
                                variant="outlined"
                              ></v-text-field>
                            </div>
                          </div>
                          <p class="redress">
                            Redress number and known traveller number
                            <span>
                              <v-icon @click="Redressnumber = !Redressnumber"
                                >mdi-menu-down</v-icon
                              >
                            </span>
                          </p>

                          <div class="row mt-4 gy-3">
                            <div class="col-md-3">
                              <v-text-field
                                v-if="Redressnumber"
                                label="Redress Number"
                                v-model="Redress"
                                variant="outlined"
                              ></v-text-field>
                            </div>
                            <div class="col-md-3">
                              <v-text-field
                                v-if="Redressnumber"
                                label="Known Traveler Number"
                                v-model="Traveler"
                                variant="outlined"
                              ></v-text-field>
                            </div>
                            <div class="col-md-6" align="end">
                              <v-btn
                                variant="outlined"
                                color="primary"
                                @click="reset1()"
                              >
                                Reset
                              </v-btn>
                            </div>
                          </div>
                        </v-card>

                        <v-card class="ffb-cart p-4" v-if="data.Apinumber">
                          <h6>Advance Passenger Information System</h6>
                          <h6>Primary Data of the Travel Document</h6>
                          <div class="row mt-3 gy-3">
                            <div class="col-lg-3">
                              <v-text-field
                                label="Last name as per passport"
                                variant="outlined"
                              ></v-text-field>
                            </div>
                            <div class="col-lg-3">
                              <v-text-field
                                label="First name as per passport"
                                variant="outlined"
                              ></v-text-field>
                            </div>
                            <div class="col-lg-3">
                              <v-text-field
                                label="2nd and additional given names    "
                                variant="outlined"
                              ></v-text-field>
                            </div>
                            <div class="col-lg-2">
                              <v-text-field
                                label="Date Of Birth"
                                type="date"
                                variant="outlined"
                              ></v-text-field>
                            </div>
                          </div>

                          <div class="row mt-4 gy-3">
                            <div class="col-3-lg">
                              <v-select
                                label="Document Type"
                                :items="[
                                  'Aeroplan-Air canada',
                                  'Flying Returns-Air india',
                                  'Air Points-Air America',
                                  'Phonix-Air china',
                                ]"
                                variant="outlined"
                              ></v-select>
                            </div>
                            <div class="col-3-lg">
                              <v-text-field
                                label="Document Number"
                                variant="outlined"
                              ></v-text-field>
                            </div>
                            <div class="col-3-lg">
                              <v-select
                                label="Issuance Country"
                                rounted="5"
                                :items="[
                                  'Aeroplan-Air canada',
                                  'Flying Returns-Air india',
                                  'Air Points-Air America',
                                  'Phonix-Air china',
                                ]"
                                variant="outlined"
                              ></v-select>
                            </div>
                            <div class="col-3-lg">
                              <v-select
                                label="Nationality"
                                :items="[
                                  'Aeroplan-Air canada',
                                  'Flying Returns-Air india',
                                  'Air Points-Air America',
                                  'Phonix-Air china',
                                ]"
                                variant="outlined"
                              ></v-select>
                            </div>
                          </div>

                          <div class="row mt-2 gy-3">
                            <div class="col-2-lg">
                              <v-text-field
                                label="Issue Date"
                                type="date"
                                variant="outlined"
                              ></v-text-field>
                            </div>
                            <div class="col-2-lg">
                              <v-text-field
                                label="Expiration"
                                type="date"
                                variant="outlined"
                              ></v-text-field>
                            </div>
                          </div>
                          <p
                            style="color: blue; cursor: pointer"
                            @click="apivalue = !apivalue"
                          >
                            Passenger contact address (DOCA)
                            <span>
                              <v-icon>mdi-menu-down</v-icon>
                            </span>
                          </p>

                          <div class="row mt-2 gy-3" v-if="apivalue">
                            <div class="col-md-2">
                              <p>Type of address</p>
                            </div>
                            <div class="col-md-10">
                              <v-radio-group
                                inline
                                color="primary"
                                v-model="value"
                              >
                                <v-radio
                                  label="Destination"
                                  value="1"
                                ></v-radio>
                                <v-radio label="Residence" value="2"></v-radio>
                              </v-radio-group>
                            </div>
                          </div>

                          <div class="row mt-4 gy-3" v-if="apivalue">
                            <div class="col-md-2">
                              <v-text-field
                                label="Address Details"
                                variant="outlined"
                              ></v-text-field>
                            </div>
                            <div class="col-md-2">
                              <v-text-field
                                label="ZIP/Postal code"
                                variant="outlined"
                              ></v-text-field>
                            </div>
                            <div class="col-md-3">
                              <v-select
                                label="Country"
                                :items="[
                                  'Aeroplan-Air canada',
                                  'Flying Returns-Air india',
                                  'Air Points-Air America',
                                  'Phonix-Air china',
                                ]"
                                variant="outlined"
                              ></v-select>
                            </div>
                            <div class="col-md-3">
                              <v-select
                                label="State/province"
                                :items="[
                                  'Aeroplan-Air canada',
                                  'Flying Returns-Air india',
                                  'Air Points-Air America',
                                  'Phonix-Air china',
                                ]"
                                variant="outlined"
                              ></v-select>
                            </div>
                            <div class="col-md-2">
                              <v-text-field
                                label="City"
                                variant="outlined"
                              ></v-text-field>
                            </div>
                          </div>
                          <div class="col-12 mt-2" align="end">
                            <v-btn variant="outlined" color="primary">
                              Reset
                            </v-btn>
                          </div>
                        </v-card>
                      </div>
                    </div>

                    <div
                      class="mt-1"
                      style="background-color: #f6f6f6; padding: 10px"
                    >
                      <h6 class="m-0">
                        {{ $t("flightInfoContent.headings.subHead1") }}
                      </h6>
                    </div>
                    <div class="row mt-3">
                      <div>
                        <div
                          class="row"
                          v-for="data of paxContacts"
                          :key="data"
                        >
                          <div class="col-md-4 mt-2">
                            <v-autocomplete
                              :items="countrycode"
                              item-title="name"
                              variant="outlined"
                              width="auto"
                              :rules="[
                                (v) => !!v || 'Country Code is required',
                              ]"
                              :label="
                                this.$t('flightInfoContent.labels.countryCode')
                              "
                              v-model="data.code"
                            >
                            </v-autocomplete>
                          </div>

                          <div class="col-md-4 mt-2">
                            <!-- <v-text-field label="Phone Number" variant="outlined"  v-model="data.number"  type="number"
     :rules="[(v) => !!v || 'Phone Number is required']"></v-text-field> -->
                            <v-text-field
                              v-model="data.number"
                              :label="
                                this.$t('flightInfoContent.labels.phoneNumber')
                              "
                              minlength="5"
                              maxlength="15"
                              :rules="phoneNumberValid"
                              variant="outlined"
                            ></v-text-field>
                          </div>

                          <div class="col-md-4 mt-2">
                            <v-text-field
                              :label="
                                this.$t('flightInfoContent.labels.emailId')
                              "
                              :rules="emailRequire"
                              variant="outlined"
                              v-model="data.email"
                            ></v-text-field>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mt-2">
                      <div class="col-lg-6">
                        <div class="row">
                          <div class="col-md-5">
                            <v-checkbox
                              v-model="contactCheck"
                              class="spl-checkbox"
                              :label="
                                this.$t(
                                  'flightInfoContent.contents.contactWhats'
                                )
                              "
                              color="success"
                            ></v-checkbox>
                          </div>
                          <div class="col-md-7">
                            <v-checkbox
                              v-model="couponsCheck"
                              class="spl-checkbox"
                              :label="
                                this.$t('flightInfoContent.contents.sendCoupon')
                              "
                              color="success"
                            ></v-checkbox>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />

                  <div
                    class="d-flex justify-center checked-box pb-3"
                    style="max-width: 100%; margin: auto"
                  >
                    <v-checkbox
                      v-model="formCheckBox"
                      class="px-3"
                      style="flex: none"
                    ></v-checkbox>
                    <div class="d-flex flex-column">
                      <span class="agree-content d-flex f-size-14"
                        >{{ $t("flightInfoContent.contents.infoText") }}
                      </span>
                      <p class="f-size-14 m-0 agree-content">
                        <a
                          href="/page/privacy-policy"
                          class="text-decoration-none"
                          >{{
                            $t("flightInfoContent.contents.privacyPolicy")
                          }}</a
                        >
                        <span class="mx-2">&</span>
                        <a
                          href="/page/terms-of-use"
                          class="text-decoration-none"
                          >{{ $t("flightInfoContent.contents.termsofUse") }}</a>
                      </p>
                    </div>
                  </div>
                  <p
                    v-if="!formCheckBox && checkForm"
                    class="w-25 ps-4 m-0 fs-12 fw-400"
                    style="color: #b00020"
                  >
                    {{ $t("flightInfoContent.contents.mustContinue") }}
                  </p>
                  <!-- <br /> -->
                </v-card>
              </div>
            </div>
          </div>

          <div class="mb-5" align="center">
            <v-btn
              width="35%"
              height="40px"
              class="continue-btn"
              type="submit"
              style="
                color: white;
                text-transform: capitalize;
                background-color: #ee2b34;
              "
              >{{ $t("flightInfoContent.contents.continue") }}</v-btn
            >
          </div>
        </div>

        <div
          class="col-lg-3 col-md-12 fare-summery1"
          style="margin-bottom: 146px"
        >
          <div class="fare-summery" :style="{ top: topSticky }">
            <v-card class="pa-3 m-auto fareCard" style="border: 1px solid gray">
              <h5>{{ $t("fareDetContent.fareDetails") }}</h5>
              <hr />
              <div class="d-flex justify-content-between">
                <div>
                  <p class="m-0" style="font-size: 16px; font-weight: 500">
                    {{ $t("fareDetContent.totalPrice") }}
                  </p>
                </div>
                <div>
                  <p class="tot-price m-0">
                    {{ currency }} {{ totalAmountpassenger }}
                  </p>
                </div>
              </div>

              <div class="row mt-2">
                <div class="col-md-12">
                  <div class="row justify-content-between">
                    <div class="col-md-6">
                      <span style="font-size: 14px; font-weight: 500">{{
                        $t("fareDetContent.passengers")
                      }}</span>
                    </div>
                    <!-- <div class="col-md-4"></div> -->
                    <div class="col-md-6 text-end" style="font-size: 14px">
                      <span v-if="adultLength > 0"
                        >{{ adultLength }} x Adult</span
                      ><br />
                      <span v-if="childLength > 0"
                        >{{ childLength }} x Child</span
                      ><br />
                      <span v-if="infLength > 0"
                        >{{ infLength }} x Lap Infant</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-3">
                <hr class="mt-1 mb-2" />
                <div class="d-flex align-end mb-2">
                  <span
                    class="not-collapsed"
                    data-bs-toggle="collapse"
                    href="#collapse-Example"
                    aria-expanded="true"
                    aria-controls="collapse-Example"
                    style="
                      padding-right: 5px;
                      cursor: pointer;
                      font-size: 14px;
                      letter-spacing: 1px;
                    "
                  >
                    <span style="color: 0D6EFD; text-decoration: underline">{{
                      $t("fareDetContent.fareSummary")
                    }}</span>
                  </span>

                  <v-icon
                    size="18"
                    class="not-collapsed"
                    data-bs-toggle="collapse"
                    href="#collapse-Example"
                    aria-expanded="true"
                    aria-controls="collapse-Example"
                    v-model="isIcon"
                    style="
                      color: 0D6EFD;
                      cursor: pointer;
                      overflow-anchor: none;
                    "
                    >{{
                      isIcon
                        ? "mdi-chevron-up-box-outline"
                        : "mdi-chevron-down-box-outline"
                    }}</v-icon
                  >
                </div>

                <div
                  class="p-1 collapse"
                  :class="{ show: isIcon }"
                  id="collapse-Example"
                >
                  <div class="row">
                    <div class="col-6 f-size-14">
                      {{ $t("fareDetContent.basePrice") }}<br />
                      {{ $t("fareDetContent.taxesFees") }}
                    </div>
                    <div class="col-6 f-size-14 fareDatas">
                      <div class="d-flex flex-row justify-end">
                        <div class="col-5 text-end pe">
                          {{ currency }} <br />
                          {{ currency }}
                        </div>
                        <div class="col-7 text-end pe-2">
                          {{ totalbasePassanger }}<br />
                          {{ totalTaxPassanger }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <hr class="mt-1 mb-2" />
                <div class="d-flex align-end mb-2">
                  <span
                    class="not-collapsed"
                    style="
                      padding-right: 5px;
                      cursor: pointer;
                      font-size: 14px;
                      letter-spacing: 1px;
                    "
                  >
                    <a
                      class="baggage border-none"
                      style="
                        font-size: 14px;
                        color: rgb(13, 110, 253);
                        text-decoration: underline;
                      "
                    >
                      <span
                        data-bs-toggle="offcanvas"
                        data-bs-target="#staticBackdrop2"
                        aria-controls="staticBackdrop"
                        @click="getFareRules()"
                        >Baggage and Cancellation Rules</span
                      >
                    </a>
                    <div class="drawer-section">
                      <div
                        class="offcanvas offcanvas-end"
                        data-bs-backdrop="static"
                        tabindex="-1"
                        id="staticBackdrop2"
                        aria-labelledby="staticBackdropLabel"
                        style="width: 35% !important"
                      >
                        <div
                          class="offcanvas-header"
                          style="
                            background: #5293cb !important;
                            color: #fff !important;
                          "
                        >
                          <h6 class="offcanvas-title" id="staticBackdrop2">
                            Baggage and Cancellation Rules
                          </h6>
                          <button
                            @click="closeBaggageRules"
                            type="button"
                            class="text-reset"
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                            color="white"
                          >
                            <v-icon>mdi-close</v-icon>
                          </button>
                        </div>
                        <div
                          class="offcanvas-body pt-0"
                          style="overflow-x: hidden"
                        >
                          <v-card class="mt-4">
                            <v-tabs v-model="tab" align-tabs="center" stacked>
                              <v-tab value="tab-1" class="m-0">
                                <v-icon>mdi-book-cancel-outline</v-icon>
                                Cancellation Rules
                              </v-tab>
                              <v-tab value="tab-2">
                                <v-icon>mdi-bag-checked</v-icon>
                                Baggage
                              </v-tab>
                            </v-tabs>

                            <v-card-text class="pt-0">
                              <keep-alive>
                                <v-window v-model="tab">
                                  <div v-if="tab === 'tab-1'">
                                    <h6 class="mt-2">Cancellation Rules:</h6>
                                    <div class="p-2 cancellationRules">
                                      <div
                                        v-if="fareRulesContent"
                                        v-html="fareRulesContent"
                                      ></div>
                                      <p v-else class="my-1">
                                        Check with Airline websites for
                                        Cancellation and Change Rules
                                      </p>
                                    </div>
                                  </div>
                                  <div v-if="tab === 'tab-2'">
                                    <h6 class="mt-1">Baggage Rules:</h6>
                                    <div
                                      class="p-2 rounded"
                                      v-for="(data, index) of roundresult"
                                      :key="index"
                                    >
                                      <div>
                                        <div
                                          style="
                                            font-size: 15px;
                                            font-weight: 500;
                                          "
                                        >
                                          {{
                                            data.Depature[0].Departure
                                              .AirportCode
                                          }}
                                          -
                                          {{
                                            data.Depature[
                                              data.Depature.length - 1
                                            ].Arrival.AirportCode
                                          }}
                                        </div>
                                        <div class="ms-2 mt-2">
                                          <!-- <div >
                                  
                                </div> -->
                                          <div
                                            class="d-flex flex-row"
                                            style="
                                              font-size: 14px;
                                              font-weight: 400;
                                              margin-left: 5px;
                                            "
                                          >
                                            <span>
                                              <v-icon
                                                color="gray"
                                                style="color: gray !important"
                                              >
                                                mdi-bag-checked
                                              </v-icon></span
                                            >
                                            <span>Carry-On:</span>
                                            <span
                                              v-if="data.depBaggage"
                                              class="ms-1"
                                            >
                                              {{
                                                data.depBaggage.PieceAllowance
                                                  .TotalQuantity > 0
                                                  ? 1 + " Piece"
                                                  : 1 + " Piece"
                                              }}
                                            </span>
                                          </div>
                                        </div>
                                        <div class="ms-2 mt-2">
                                          <!-- <div>
                                  
                                </div> -->
                                          <div
                                            class="d-flex flex-row"
                                            style="
                                              font-size: 14px;
                                              font-weight: 400;
                                              margin-left: 5px;
                                            "
                                          >
                                            <span>
                                              <v-icon
                                                color="gray"
                                                style="color: gray !important"
                                              >
                                                mdi-bag-suitcase
                                              </v-icon></span
                                            >
                                            <span> Checked Bag:</span>
                                            <span
                                              v-if="data.depBaggage"
                                              class="ms-1"
                                            >
                                              {{
                                                data.depBaggage.PieceAllowance
                                                  .TotalQuantity || 0
                                              }}

                                              {{
                                                data.depBaggage.PieceAllowance
                                                  .TotalQuantity > 1
                                                  ? "Pieces"
                                                  : "Piece"
                                              }}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                      <hr v-if="data.Return" />
                                      <div v-if="data.Return" class="mt-2">
                                        <div
                                          class="mt-1"
                                          style="
                                            font-size: 15px;
                                            font-weight: 500;
                                          "
                                        >
                                          {{
                                            data.Depature[
                                              data.Depature.length - 1
                                            ].Arrival.AirportCode
                                          }}
                                          -
                                          {{
                                            data.Depature[0].Departure
                                              .AirportCode
                                          }}
                                        </div>
                                        <!-- <div v-if="flightdatas.arrBaggage.PieceAllowance.TotalQuantity != 0 "></div> -->
                                        <div class="ms-2 mt-2">
                                          <!-- <div >
                                  
                                </div> -->
                                          <div
                                            class="d-flex flex-row"
                                            style="
                                              font-size: 14px;
                                              font-weight: 400;
                                              margin-left: 5px;
                                            "
                                          >
                                            <span>
                                              <v-icon
                                                color="gray"
                                                style="color: gray !important"
                                              >
                                                mdi-bag-checked
                                              </v-icon></span
                                            >
                                            <span>Carry-On:</span>
                                            <span class="ms-1">
                                              {{
                                                data.arrBaggage.PieceAllowance
                                                  .TotalQuantity > 0
                                                  ? 1 + " Piece"
                                                  : 1 + " Piece"
                                              }}
                                            </span>
                                          </div>
                                        </div>
                                        <div class="ms-2 mt-1">
                                          <!-- <div style="color: gray !important">
                                  
                                </div> -->
                                          <div
                                            class="d-flex flex-row"
                                            style="
                                              font-size: 14px;
                                              font-weight: 400;
                                              margin-left: 5px;
                                            "
                                          >
                                            <span>
                                              <v-icon
                                                color="gray"
                                                style="color: gray !important"
                                              >
                                                mdi-bag-suitcase
                                              </v-icon></span
                                            >
                                            <span> Checked Bag:</span>
                                            <!-- <span class="ms-1"> {{res.arrBaggage.PieceAllowance.TotalQuantity}}Piece</span> -->
                                            <span class="ms-1">
                                              {{
                                                data.arrBaggage.PieceAllowance
                                                  .TotalQuantity || 0
                                              }}
                                              {{
                                                data.arrBaggage.PieceAllowance
                                                  .TotalQuantity > 1
                                                  ? "Pieces"
                                                  : "Piece"
                                              }}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div v-if="onewayresult">
                                      <div
                                        class="p-2 rounded"
                                        v-for="(data, index) of onewayresult"
                                        :key="index"
                                      >
                                        <div>
                                          <div
                                            style="
                                              font-size: 15px;
                                              font-weight: 500;
                                            "
                                          >
                                            {{
                                              data.Depature[0].Departure
                                                .AirportCode
                                            }}
                                            -
                                            {{
                                              data.Depature[
                                                data.Depature.length - 1
                                              ].Arrival.AirportCode
                                            }}
                                          </div>
                                          <div class="ms-2 mt-2">
                                            <!-- <div >
                                  
                                </div> -->
                                            <div
                                              class="d-flex flex-row"
                                              style="
                                                font-size: 14px;
                                                font-weight: 400;
                                                margin-left: 5px;
                                              "
                                            >
                                              <span>
                                                <v-icon
                                                  color="gray"
                                                  style="color: gray !important"
                                                >
                                                  mdi-bag-checked
                                                </v-icon></span
                                              >
                                              <span>Carry-On:</span>
                                              <span
                                                v-if="data.BaggageData"
                                                class="ms-1"
                                              >
                                                {{
                                                  data.BaggageData
                                                    .PieceAllowance
                                                    .TotalQuantity > 0
                                                    ? 1 + " Piece"
                                                    : 1 + " Piece"
                                                }}
                                              </span>
                                            </div>
                                          </div>
                                          <div class="ms-2 mt-2">
                                            <!-- <div>
                                  
                                </div> -->
                                            <div
                                              class="d-flex flex-row"
                                              style="
                                                font-size: 14px;
                                                font-weight: 400;
                                                margin-left: 5px;
                                              "
                                            >
                                              <span>
                                                <v-icon
                                                  color="gray"
                                                  style="color: gray !important"
                                                >
                                                  mdi-bag-suitcase
                                                </v-icon></span
                                              >
                                              <span> Checked Bag:</span>
                                              <span class="ms-1">
                                                {{
                                                  data.BaggageData
                                                    .PieceAllowance
                                                    .TotalQuantity || 0
                                                }}

                                                {{
                                                  data.BaggageData
                                                    .PieceAllowance
                                                    .TotalQuantity > 1
                                                    ? "Pieces"
                                                    : "Piece"
                                                }}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div v-if="multiresult">
                                      <div
                                        class="border p-2 rounded"
                                        v-for="(data, index) of multiresult"
                                        :key="index"
                                      >
                                        <div>
                                          <div
                                            style="
                                              font-size: 15px;
                                              font-weight: 500;
                                            "
                                          >
                                            {{
                                              data.Flight1[0].Departure
                                                .AirportCode
                                            }}
                                            -
                                            {{
                                              data.Flight1[
                                                data.Flight1.length - 1
                                              ].Arrival.AirportCode
                                            }}
                                          </div>
                                          <div class="ms-2 mt-2">
                                            <div
                                              class="d-flex flex-row"
                                              style="
                                                font-size: 14px;
                                                font-weight: 400;
                                                margin-left: 5px;
                                              "
                                            >
                                              <span>
                                                <v-icon
                                                  color="gray"
                                                  style="color: gray !important"
                                                >
                                                  mdi-bag-checked
                                                </v-icon></span
                                              >
                                              <span>Carry-On: </span>
                                              <span class="">{{
                                                data.BaggageData1.PieceAllowance
                                                  .TotalQuantity > 0
                                                  ? 1 + " Piece"
                                                  : 1 + " Piece"
                                              }}</span>
                                            </div>
                                          </div>
                                          <div class="ms-2 mt-2">
                                            <!-- <div>
                                  
                                </div> -->
                                            <div
                                              class="d-flex flex-row"
                                              style="
                                                font-size: 14px;
                                                font-weight: 400;
                                                margin-left: 5px;
                                              "
                                            >
                                              <span>
                                                <v-icon
                                                  color="gray"
                                                  style="color: gray !important"
                                                >
                                                  mdi-bag-suitcase
                                                </v-icon></span
                                              >
                                              <span> Checked Bag: </span>
                                              <span class="mt-1">
                                                {{
                                                  data.BaggageData1
                                                    .PieceAllowance
                                                    .TotalQuantity || 0
                                                }}{{
                                                  data.BaggageData1
                                                    .PieceAllowance
                                                    .TotalQuantity > 1
                                                    ? " Pieces"
                                                    : " Piece"
                                                }}</span
                                              >
                                            </div>
                                          </div>

                                          <div class="ms-2 mt-2">
                                            <!-- <div >
                                  
                                </div> -->
                                          </div>
                                        </div>
                                        <hr v-if="data.Flight2" />
                                        <div v-if="data.Flight2" class="mt-2">
                                          <div
                                            class="mt-1"
                                            style="
                                              font-size: 15px;
                                              font-weight: 500;
                                            "
                                          >
                                            {{
                                              data.Flight2[0].Departure
                                                .AirportCode
                                            }}
                                            -
                                            {{
                                              data.Flight2[
                                                data.Flight2.length - 1
                                              ].Arrival.AirportCode
                                            }}
                                          </div>
                                          <!-- <div v-if="flightdatas.arrBaggage.PieceAllowance.TotalQuantity != 0 "></div> -->
                                          <div class="ms-2 mt-2">
                                            <!-- <div >
                                  
                                </div> -->
                                            <div
                                              class="d-flex flex-row"
                                              style="
                                                font-size: 14px;
                                                font-weight: 400;
                                                margin-left: 5px;
                                              "
                                            >
                                              <span>
                                                <v-icon
                                                  color="gray"
                                                  style="color: gray !important"
                                                >
                                                  mdi-bag-checked
                                                </v-icon></span
                                              >
                                              <span>Carry-On: </span>
                                              <span class="">{{
                                                data.BaggageData2.PieceAllowance
                                                  .TotalQuantity > 0
                                                  ? 1 + " Piece"
                                                  : 1 + " Piece"
                                              }}</span>
                                            </div>
                                          </div>
                                          <div class="ms-2 mt-2">
                                            <!-- <div>
                                  
                                </div> -->
                                            <div
                                              class="d-flex flex-row"
                                              style="
                                                font-size: 14px;
                                                font-weight: 400;
                                                margin-left: 5px;
                                              "
                                            >
                                              <span>
                                                <v-icon
                                                  color="gray"
                                                  style="color: gray !important"
                                                >
                                                  mdi-bag-suitcase
                                                </v-icon></span
                                              >
                                              <span> Checked Bag: </span>
                                              <span class="mt-1">
                                                {{
                                                  data.BaggageData2
                                                    .PieceAllowance
                                                    .TotalQuantity || 0
                                                }}{{
                                                  data.BaggageData2
                                                    .PieceAllowance
                                                    .TotalQuantity > 1
                                                    ? " Pieces"
                                                    : " Piece"
                                                }}</span
                                              >
                                            </div>
                                          </div>
                                        </div>
                                        <hr v-if="data.Flight3" />
                                        <div v-if="data.Flight3" class="mt-2">
                                          <div
                                            class="mt-1"
                                            style="
                                              font-size: 15px;
                                              font-weight: 500;
                                            "
                                          >
                                            {{
                                              data.Flight3[0].Departure
                                                .AirportCode
                                            }}
                                            -
                                            {{
                                              data.Flight3[
                                                data.Flight3.length - 1
                                              ].Arrival.AirportCode
                                            }}
                                          </div>
                                          <!-- <div v-if="flightdatas.arrBaggage.PieceAllowance.TotalQuantity != 0 "></div> -->
                                          <div class="ms-2 mt-2">
                                            <!-- <div >
                                  
                                </div> -->
                                            <div
                                              class="d-flex flex-row"
                                              style="
                                                font-size: 14px;
                                                font-weight: 400;
                                                margin-left: 5px;
                                              "
                                            >
                                              <span>
                                                <v-icon
                                                  color="gray"
                                                  style="color: gray !important"
                                                >
                                                  mdi-bag-checked
                                                </v-icon></span
                                              >
                                              <span>Carry-On: </span>
                                              <span class="">{{
                                                data.BaggageData3.PieceAllowance
                                                  .TotalQuantity > 0
                                                  ? 1 + " Piece"
                                                  : 1 + " Piece"
                                              }}</span>
                                            </div>
                                          </div>
                                          <div class="ms-2 mt-2">
                                            <!-- <div>
                                  
                                </div> -->
                                            <div
                                              class="d-flex flex-row"
                                              style="
                                                font-size: 14px;
                                                font-weight: 400;
                                                margin-left: 5px;
                                              "
                                            >
                                              <span>
                                                <v-icon
                                                  color="gray"
                                                  style="color: gray !important"
                                                >
                                                  mdi-bag-suitcase
                                                </v-icon></span
                                              >
                                              <span> Checked Bag: </span>
                                              <span class="mt-1">
                                                {{
                                                  data.BaggageData3
                                                    .PieceAllowance
                                                    .TotalQuantity || 0
                                                }}{{
                                                  data.BaggageData3
                                                    .PieceAllowance
                                                    .TotalQuantity > 1
                                                    ? " Pieces"
                                                    : " Piece"
                                                }}</span
                                              >
                                            </div>
                                          </div>
                                        </div>
                                        <hr v-if="data.Flight4" />
                                        <div v-if="data.Flight4" class="mt-2">
                                          <div
                                            class="mt-1"
                                            style="
                                              font-size: 15px;
                                              font-weight: 500;
                                            "
                                          >
                                            {{
                                              data.Flight4[0].Departure
                                                .AirportCode
                                            }}
                                            -
                                            {{
                                              data.Flight4[
                                                data.Flight4.length - 1
                                              ].Arrival.AirportCode
                                            }}
                                          </div>
                                          <!-- <div v-if="flightdatas.arrBaggage.PieceAllowance.TotalQuantity != 0 "></div> -->
                                          <div class="ms-2 mt-2">
                                            <!-- <div >
                                  
                                </div> -->
                                            <div
                                              class="d-flex flex-row"
                                              style="
                                                font-size: 14px;
                                                font-weight: 400;
                                                margin-left: 5px;
                                              "
                                            >
                                              <span>
                                                <v-icon
                                                  color="gray"
                                                  style="color: gray !important"
                                                >
                                                  mdi-bag-checked
                                                </v-icon></span
                                              >
                                              <span>Carry-On: </span>
                                              <span class="">{{
                                                data.BaggageData4.PieceAllowance
                                                  .TotalQuantity > 0
                                                  ? 1 + " Piece"
                                                  : 1 + " Piece"
                                              }}</span>
                                            </div>
                                          </div>
                                          <div class="d-flex ms-2 mt-2">
                                            <!-- <div>
                                  
                                </div> -->
                                            <div
                                              style="
                                                font-size: 14px;
                                                font-weight: 400;
                                                margin-left: 5px;
                                              "
                                            >
                                              <span
                                                ><v-icon
                                                  color="gray"
                                                  style="color: gray !important"
                                                >
                                                  mdi-bag-suitcase
                                                </v-icon></span
                                              >
                                              <span> Checked Bag: </span>
                                              <span class="mt-1">
                                                {{
                                                  data.BaggageData4
                                                    .PieceAllowance
                                                    .TotalQuantity || 0
                                                }}{{
                                                  data.BaggageData4
                                                    .PieceAllowance
                                                    .TotalQuantity > 1
                                                    ? " Pieces"
                                                    : " Piece"
                                                }}</span
                                              >
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </v-window>
                              </keep-alive>
                            </v-card-text>
                          </v-card>
                        </div>
                      </div>
                    </div>
                  </span>
                </div>
                <!-- <hr class="mt-1 mb-2" /> -->

                <br />
                <p style="font-size: 12px">
                  {{ $t("fareDetContent.priceInfo1") }} {{ metaCurrency }} .{{
                    $t("fareDetContent.priceInfo2")
                  }}
                </p>
              </div>
            </v-card>

            <div class="mt-4 mb-2" align="center">
              <v-btn
                width="80%"
                height="40px"
                type="submit"
                style="
                  font-size: 16px;
                  font-weight: 500;
                  color: white;
                  text-transform: capitalize;
                  background-color: #ee2b34;
                "
              >
                {{ $t("flightInfoContent.contents.continue") }}</v-btn
              >
            </div>
          </div>
        </div>
      </div>
    </v-form>
    <v-card max-width="100%" class="p-3">
      <div v-if="totalSeat.length > 0">
        <div class="row">
          <div class="col-md-1" v-for="(data, index) of totalSeat" :key="index">
            <v-btn variant="outlined" @click="selectdata(data, index)">
              Flight{{ index + 1 }}</v-btn
            >
          </div>
        </div>

        <div class="row mt-2">
          <div
            class="col-md-2"
            v-for="(data, index) of totalpassanger"
            :key="index"
          >
            <v-btn
              variant="outlined"
              @click="totalpassData(data, index)"
              :class="passSelect == index ? 'tru-pass' : ''"
            >
              Passenger{{ index + 1 }}</v-btn
            >
          </div>
        </div>

        <div
          style="overflow-x: scroll; border: 1px solid lightgrey"
          class="mt-2 pa-2"
        >
          <div
            v-for="(data, index) of totalSeat[indexData].cabins[0].columns"
            :key="index"
            class="d-flex mt-2"
          >
            <div class="px-1 text-center">
              <div style="width: 25px">{{ data.column }}</div>
            </div>
            <div
              class="px-1 text-center"
              v-for="(datas, index1) of totalData[data.column]"
              :key="index1"
            >
              <div
                :class="{
                  'true-sheet': datas.seats[0].occupied || datas.color,
                  'false-sheet': !datas.seats[0].occupied,
                  'tru-icon': datas.color,
                }"
                @click="seatSelectdata(datas, index1, data.column)"
              >
                <v-icon :color="datas.seats[0].occupied ? 'green' : 'gray'"
                  >mdi-seat-passenger</v-icon
                >
              </div>

             
              <div>
                <div v-if="data.space">
                  {{ datas.row_number }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-card>
  </v-container>

  <div v-if="dataloader">
    <dataloader></dataloader>
  </div>
  <v-dialog v-model="dialog1" persistent>
    <div class="d-flex justify-center">
      <v-card class="p-4">
        <h3 class="text-center">OOPS!</h3>
        <h5>Your request cannot be processed at this moment.</h5>
        <h6>Please contact customer support.</h6>
        <div class="d-flex justify-space-evenly mt-3">
          <v-btn @click="tryAgain1()" color="#ee2b34">Try Again</v-btn>

          <v-btn to="/" color="#ee2b34">Return to Home</v-btn>
        </div>
      </v-card>
    </div>
  </v-dialog>

  <v-dialog v-model="dialog2" persistent>
    <div class="d-flex justify-center">
      <v-card class="p-4">
        <h3 class="text-center">OOPS!</h3>
        <h5>Your request cannot be processed at this moment.</h5>
        <h6>Please contact customer support.</h6>
        <div class="d-flex justify-space-evenly mt-3">
          <v-btn @click="tryAgain2()" color="#ee2b34">Try Again</v-btn>

          <v-btn to="/" color="#ee2b34">Return to Home</v-btn>
        </div>
      </v-card>
    </div>
  </v-dialog>
</template>

<script>
import moment from "moment";
// import VueDatePicker from "@vuepic/vue-datepicker";
// import "@vuepic/vue-datepicker/dist/main.css";
import airLogo from "@/airlinesLogo.json";
import axios from "axios";
import country1 from "@/coundtrycode.json";
import dataloader from "@/components/dataLoder.vue";

import { getConfigDataFromLocalStorage } from "@/localStorageData/localStorageUtil.js";

export default {
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },

  components: {
    // headerCom,
    // footerCom,
    // travellerDetail,
    // VueDatePicker,
    dataloader,
  },
  data() {
    const monthListAll = Array.from({ length: 12 }, (_, i) => {
      const date = new Date(2000, i, 1); // Use any year, here I used 2000
      const monthName = date
        .toLocaleString("default", { month: "short" })
        .substring(0, 3);
      return monthName;
    });

    return {
      tab: null,
      fareRulesContent: "",
      shoppingid: "",
      selectId: "",
      portal_id: "",
      pointScale: "",
      checkOutPage: true,
      dataloader: true,
      showConfirmPage: false,
      dialog1: false,
      dialog2: false,
      airlogodata: [],
      portal: false,
      countrycode: [],
      portal_shopId: "",
      portal_offId: "",
      limitPosition: 500,
      scrolled: false,
      lastPosition: 0,
      topSticky: "23%",
      booking_Id: "",
      formCheckBox: false,
      checkForm: false,
      datenumber: false,
      contactCheck: true,
      couponsCheck: true,
      totalAmountpassenger: [],
      currency: "",
      totalTaxPassanger: [],
      totalbasePassanger: [],
      valid1: false,
      ffb: "",
      air: "Aeroplan-Air canada",
      Redress: "",
      Traveler: "",
      Redressnumber: true,
      result: "name",
      value: "1",
      dateOfBirth: "",
      dateFormat: "dd MMM yyyy",
      apivalue: true,
      dateData: [],
      yearData: [],
      portalData: [],
      mode: true,

      passengersDatas: {
        adultDatas: [],
        childDatas: [],
        infrantDatas: [],
      },

      adultData: [],
      childData: [],
      infrantData: [],
      adult: "",
      child: "",
      inf: "",

      paxContacts: [
        {
          number: "",
          email: "",
        },
      ],

      emailRequire: [
        (v) => !!v || "This field is required",
        (v) => /.+@.+\..+/.test(v) || "Please enter a valid email address",
      ],

      phoneNumberValid: [
        (v) => !!v || "Phone Number is required",
        (v) => /^\+?\d{5,15}$/.test(v) || "Invalid Phone Number",
      ],

      //     emailRequire: {
      //   required: (v) => !!v || 'Email is required',
      //   validEmail: (v) => /.+@.+\..+/.test(v) || 'Please enter a valid email address'
      // }

      itineraryInfo: false,
      indexData: 0,
      itineraryInfo1: false,
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,
      flightInfo: false,
      flightDetail: false,
      bagInfo: false,
      baggageDetails: false,
      overlaySelector: false,
      valid: true,
      titleBox: true,
      roundresult: [],
      onewayresult: [],
      multiresult: [],
      isIcon: false,
      fromData: "",
      toData: "",
      adultLength: "",
      childLength: "",
      infLength: "",
      classed: "",
      fareItems: [],
      adultFare: [],
      childFare: [],
      infrantFare: [],
      adultprice: [],
      childprice: [],
      infprice: [],
      adulttax: [],
      childtax: [],
      inftax: [],
      totaltax: [],
      totalprice: [],
      totalamount: [],
      layoverTimes: [],
      portalResData: [],
      fareshow: false,
      // dobError:false,
      // clicked1: false,
      offerData1: [],
      offerData2: [],
      offerData3: [],
      offerData4: [],
      Flight2: [],
      Flight3: [],
      Flight4: [],
      Flight1: [],
      dobAdultError: false,
      // dobChildError: false,
      // dobInfrantError: false,

      dataLink: "",

      bookingviewApi: "",
      metaCurrency: "",
      displayCurrency: "",

      adultpax: {
        firstname: [],
        lastname: [],
        middlename: [],
        salutation: [],
        gender: [],
        dob: [],
      },
      chd: {
        firstname: [],
        lastname: [],
        middlename: [],
        salutation: [],
        gender: [],
        dob: [],
      },

      infpax: {
        firstname: [],
        lastname: [],
        middlename: [],
        salutation: [],
        gender: [],
        dob: [],
      },

      local_id: "",
      Localoneway_id: null,
      seatSelect: [],
      totalSeat: [],
      totalData: [],
      totalpassanger: "",
      passSelect: 0,
      passData: 0,

      titles: ["Flight", "Passenger", "Request", "Free Text"],
      specialBox: [
        {
          fBox: null,
          pBox: "Adult 1",
          rBox: null,
          freeBox: "",
        },
      ],
      selectFlight: [
        "California",
        "Colorado",
        "Florida",
        "Georgia",
        "Texas",
        "Wyoming",
      ],
      selectPassanger: ["Adult 1"],
      selectRequest: [
        "California",
        "Colorado",
        "Florida",
        "Georgia",
        "Texas",
        "Wyoming",
      ],

      flightValid: [(v) => !!v || "Field is required"],
      passengerValid: [(v) => !!v || "Field is required"],
      requestValid: [(v) => !!v || "Field is required"],
      // textValid:[v => !!v || 'Field is required'],

      firstName: [
        (v) => !!v || "Please enter your First Name",
        (v) =>
          /^[a-zA-Z -]+$/.test(v) ||
          "Only alphabets, hyphens, and spaces are allowed",
      ],

      lastName: [
        (v) => !!v || "Please enter your Last Name",
        (v) =>
          /^[a-zA-Z -]+$/.test(v) ||
          "Only alphabets, hyphens, and spaces are allowed",
      ],

      middleName: [
        (v) => !!v || "Please enter your Middle Name",
        (v) =>
          /^[a-zA-Z -]+$/.test(v) ||
          "Only alphabets, hyphens, and spaces are allowed",
      ],

      segmentList: [],

      monthListOriginal: [...monthListAll],
      monthList: [...monthListAll],
      storeMonthList: [],
    };
  },
  methods: {
    closeBaggageRules() {
      this.tab = "tab-1";
    },
    getsegment() {
      if (this.roundresult) {
        this.roundresult.forEach((v) => {
          v.Depature.forEach((s) => {
            this.segmentList.push(s);
          });
          v.Return.forEach((e) => {
            this.segmentList.push(e);
          });
        });
        console.log(this.segmentList, "this.segmentListthis.segmentList...1.");
      }

      if (this.onewayresult) {
        this.onewayresult.forEach((v) => {
          v.Depature.forEach((s) => {
            this.segmentList.push(s);
          });
        });
        console.log(this.onewayresult, "this.segmentListthis.segmentList...2.");
      }

      if (this.multiresult) {
        this.multiresult.forEach((v) => {
          if (v.Flight1) {
            v.Flight1.forEach((s) => {
              this.segmentList.push(s);
            });
          }
          if (v.Flight2) {
            v.Flight2.forEach((s) => {
              this.segmentList.push(s);
            });
          }
          if (v.Flight3) {
            v.Flight3.forEach((s) => {
              this.segmentList.push(s);
            });
          }
          if (v.Flight4) {
            v.Flight4.forEach((s) => {
              this.segmentList.push(s);
            });
          }
          if (v.Flight5) {
            v.Flight5.forEach((s) => {
              this.segmentList.push(s);
            });
          }
        });
        console.log(this.segmentList, "this.segmentListthis.segmentList...3.");
      }
    },

    inputHandle(data, index) {
      if (data.Type == "Adult") {
        if (data.dateDob.year.length < 4) {
          this.adultData.map((v, i) => {
            if (index == i) {
              v.dateDob.month = null;
              v.dateDob.date = null;
              v.isYearValid = false;
            }
          });
        }
      } else if (data.Type == "Child") {
        if (data.dateDob.year.length < 4) {
          this.childData.map((v, i) => {
            if (index == i) {
              v.dateDob.month = null;
              v.dateDob.date = null;
              v.isYearValid = false;
            }
          });
        }
      } else if (data.Type == "Infrant") {
        if (data.dateDob.year.length < 4) {
          this.infrantData.map((v, i) => {
            if (index == i) {
              v.dateDob.month = null;
              v.dateDob.date = null;
              v.isYearValid = false;
            }
          });
        }
      }
    },

    inputChange(data, index) {
      // console.log(data,index,'data,indexdata,indexdata,index')

      const enteredYear = data.dateDob.year;

      if (data.Type == "Adult") {
        this.adultData.map((v, i) => {
          if (index == i) {
            v.dateDob.date = null;
          }
        });

        this.monthList = [...this.monthListOriginal];
        console.log(this.monthList, "this.monthListthis.monthList....1");
      } else if (data.Type == "Child") {
        this.childData.map((v, i) => {
          if (index == i) {
            v.dateDob.date = null;
          }
        });

        this.monthList = [...this.monthListOriginal];
        console.log(this.monthList, "this.monthListthis.monthList....2");
      } else if (data.Type == "Infrant") {
        this.infrantData.map((v, i) => {
          if (index == i) {
            v.dateDob.date = null;
          }
        });

        let currentDate = moment(
          this.segmentList[this.segmentList.length - 1].Departure.Date
        ).format("YYYY-MMM-DD");
        let maximumDate = new Date(
          new Date(currentDate).getFullYear(),
          new Date(currentDate).getMonth(),
          new Date(currentDate).getDate() - 15
        );
        let maxMonth = moment(maximumDate, "YYYY-MMM-DD").format("MMM");

        console.log(currentDate, "currentDate...", maxMonth, "maxMonth...");

        if (enteredYear == new Date(maximumDate).getFullYear()) {
          const maxMonthIndex = this.monthListOriginal.indexOf(maxMonth);
          this.monthList = [...this.monthListOriginal];
          this.monthList.splice(maxMonthIndex + 1);
          console.log(this.monthList, "this.monthListthis.monthList....3");
        } else {
          this.monthList = [...this.monthListOriginal];
          console.log(this.monthList, "this.monthListthis.monthList.....4");
        }
      }
    },

    yearRules(Type, index) {
      return [
        (v) => !!v || "Please enter the Year",
        (v) => /^\d+$/.test(v) || "Please enter a valid Year",
        (v) => {
          const currentYear = new Date(
            this.segmentList[0].Departure.Date
          ).getFullYear();
          const enteredYear = parseInt(v, 10);

          let minYear, maxYear;

          if (Type === "Adult") {
            minYear = currentYear - 100;
            maxYear = currentYear - 12;

            if (v.length === 4) {
              console.log(enteredYear, "enteredYearenteredYear");
              const isValid = enteredYear >= minYear && enteredYear <= maxYear;
              if (isValid) {
                this.adultData.map((v, i) => {
                  if (index == i) {
                    v.isYearValid = true;
                  }
                });
              }
              return (
                isValid || `Enter a year between ${minYear} and ${maxYear}`
              );
            }
          } else if (Type === "Child") {
            this.segmentList.forEach((v) => {
              if (this.segmentList.length - 1) {
                maxYear = new Date(v.Departure.Date).getFullYear();
              }
            });

            maxYear = currentYear - 2;
            minYear = maxYear - 10;

            console.log(minYear, "minYearminYearminYear");

            if (v.length === 4) {
              const isValid = enteredYear >= minYear && enteredYear <= maxYear;
              if (isValid) {
                this.childData.map((v, i) => {
                  if (index == i) {
                    v.isYearValid = true;
                  }
                });
              }
              return (
                isValid || `Enter a year between ${minYear} and ${maxYear}`
              );
            }
          } else if (Type === "Infrant") {
            let currentDate = moment(
              this.segmentList[this.segmentList.length - 1].Departure.Date
            ).format("YYYY-MMM-DD");
            let maximumDate = new Date(
              new Date(currentDate).getFullYear(),
              new Date(currentDate).getMonth() - 1,
              new Date(currentDate).getDate()
            );
            let maxDate = moment(maximumDate, "YYYY-MMM-DD").format(
              "YYYY-MMM-DD"
            );

            maxYear = new Date(maxDate).getFullYear();
            minYear = maxYear - 2;

            if (v.length === 4) {
              const isValid = enteredYear >= minYear && enteredYear <= maxYear;
              if (isValid) {
                this.infrantData.map((v, i) => {
                  if (index == i) {
                    v.isYearValid = true;
                  }
                });
              }
              return (
                isValid || `Enter a year between ${minYear} and ${maxYear}`
              );
            }
          }
        },
      ];
    },

    monthRules(Type, index) {
      return [
        // () => (!!this.adultData[index].dateDob.year || !!this.childData[index].dateDob.year || !!this.infrantData[index].dateDob.year) || `Please enter the Year first`,
        () => {
          if (Type === "Adult") {
            if (!this.adultData[index].dateDob.year) {
              return (
                !!this.adultData[index].dateDob.year ||
                `Please enter the Year first`
              );
            }
          } else if (Type === "Child") {
            if (!this.childData[index].dateDob.year) {
              return (
                !!this.childData[index].dateDob.year ||
                `Please enter the Year first`
              );
            }
          } else if (Type === "Infrant") {
            if (!this.infrantData[index].dateDob.year) {
              return (
                !!this.infrantData[index].dateDob.year ||
                `Please enter the Year first`
              );
            }
          }
        },

        (v) => !!v || `Please enter the Month`,
      ];
    },

    dateRules(data, index) {
      return [
        () => {
          if (data.Type === "Adult") {
            if (
              !this.adultData[index].dateDob.year &&
              !this.adultData[index].dateDob.month
            ) {
              return (
                (!!this.adultData[index].dateDob.year &&
                  !!this.adultData[index].dateDob.month) ||
                `Please enter the Year and Month first`
              );
            }
          } else if (data.Type === "Child") {
            if (
              !this.childData[index].dateDob.year &&
              !this.childData[index].dateDob.month
            ) {
              return (
                (!!this.childData[index].dateDob.year &&
                  !!this.childData[index].dateDob.month) ||
                `Please enter the Year and Month first`
              );
            }
          } else if (data.Type === "Infrant") {
            if (
              !this.infrantData[index].dateDob.year &&
              !this.infrantData[index].dateDob.month
            ) {
              return (
                (!!this.infrantData[index].dateDob.year &&
                  !!this.infrantData[index].dateDob.month) ||
                `Please enter the Year and Month first`
              );
            }
          }
        },

        (v) => /^\d+$/.test(v) || "Please enter the date",
        (v) => (v >= 1 && v <= 31) || "Please enter a valid date",
        (v) => /^\d{2}$/.test(v) || 'format like "05"',

        (v) => {
          const dataDob =
            data.Type === "Adult"
              ? this.adultData[index].dateDob
              : data.Type === "Child"
              ? this.childData[index].dateDob
              : this.infrantData[index].dateDob;

          const year = dataDob.year;
          const month = dataDob.month;

          if (year && month) {
            console.log(year, month, "yearyearyearyear");
            const monthIndex = this.monthList.indexOf(month);
            console.log(monthIndex, "monthIndexmonthIndexmonthIndex");
            console.log(this.monthList, "monthListmonthListmonthList");
            const lastDayOfMonth = new Date(year, monthIndex + 1, 0).getDate();
            console.log(lastDayOfMonth, "lastDayOfMonthlastDayOfMonth");
            return (
              (v >= 1 && v <= lastDayOfMonth) || `Invalid date for ${month}`
            );
          }

          return true;
        },

        (v) => {
          let currentDate = moment(
            this.segmentList[this.segmentList.length - 1].Departure.Date
          ).format("YYYY-MMM-DD");
          let maximumDate = new Date(
            new Date(currentDate).getFullYear(),
            new Date(currentDate).getMonth(),
            new Date(currentDate).getDate() - 15
          );
          let formatDate = moment(maximumDate, "YYYY-MMM-DD").format(
            "YYYY-MMM-DD"
          );
          let maxYear = new Date(formatDate).getFullYear();
          let maxMonth = moment(maximumDate, "YYYY-MMM-DD").format("MMM");
          let maxDate = new Date(formatDate).getDate();
          maxDate = maxDate.toString().padStart(2, "0");
          console.log(maxDate, "maxDatemaxDatemaxDate..111.");
          console.log(
            currentDate,
            "currentDate....",
            maximumDate,
            "maximumDatemaximumDatemaximumDate..."
          );

          if (data.Type === "Infrant") {
            let normalDate = v >= 1 && v <= 31;
            let validDate = v >= 1 && v <= maxDate;

            if (
              maxYear == data.dateDob.year &&
              maxMonth == data.dateDob.month
            ) {
              return (
                validDate ||
                `Allow travel before ${maxMonth},${maxDate} for infrants`
              );
            } else {
              return normalDate;
            }
          }
        },
      ];
    },

    tryAgain1() {
      console.log(this.dataLink, "this.dataLinkthis.dataLink....1");
      this.dialog1 = false;
      this.portelDataConvert();
    },
    tryAgain2() {
      console.log(this.dataLink, "this.dataLinkthis.dataLink....2");
      this.dialog2 = false;

      if (this.local_id) {
        this.$router.push({
          path: "/confirmBook",
          query: { pass_uid: `${this.local_id}` },
        });

        setTimeout(() => {
          this.showConfirmPage = false;
          this.dialog2 = true;
        }, 5000);
      } else {
        this.$router.push({
          path: "/confirmBook",
          query: { pass_uid1: `${this.Localoneway_id}` },
        });

        setTimeout(() => {
          this.showConfirmPage = false;
          this.dialog2 = true;
        }, 5000);
      }
    },

    filter: function (evt) {
      evt = evt ? evt : window.event;
      let expect = evt.target.value.toString() + evt.key.toString();

      if (!/^[-+]?[0-9]*\.?[0-9]*$/.test(expect)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    flight1(data) {
      console.log(data, "pppppppppppppppppp");
      data.Flightdepature = true;
    },
    flight2(data) {
      console.log(data, "pppppppppppppppppp");
      data.Flightreturn = true;
    },
    closeDep(data) {
      data.Flightdepature = false;
    },
    closearr(data) {
      data.Flightreturn = false;
    },

    flight() {
      this.overlaySelector = true;
      this.baggageDetails = false;
      this.flightInfo = true;
      this.flightDetail = true;
      this.bagInfo = false;
    },
    overlay() {
      this.overlaySelector = false;
    },
    itinerary() {
      this.dialog = true;
      this.overlaySelector = true;
      this.flightInfo = true;
      this.flightDetail = true;
      this.baggageDetails = false;
      this.bagInfo = true;
    },
    baggage() {
      this.dialog = true;
      this.overlaySelector = true;
      this.flightInfo = true;
      this.flightDetail = false;
      this.baggageDetails = true;
      this.bagInfo = true;
    },
    closedialog() {
      this.dialog = false;
      this.overlaySelector = false;
    },
    closedialog1() {
      this.dialog = false;
      this.overlaySelector = false;
    },
    addBox() {
      if (this.valid && this.specialBox.length < 5) {
        this.specialBox.push({
          // fBox: null,
          pBox: "Adult 1",
          // rBox: null,
          // freeBox: null,
          ...this.specialBox,
        });
      }
      console.log(this.valid, "rest");
    },
    resetValues() {
      this.valid = false;
      this.specialBox = [
        {
          fBox: null,
          pBox: "Adult 1",
          rBox: null,
          freeBox: "",
        },
      ];
    },

    getdepcode(data) {
      console.log(data, "getdepcode");
      return data[0].Departure.AirportCode;
    },
    getarrcode(data) {
      return data[data.length - 1].Arrival.AirportCode;
    },
    depdate(data) {
      return moment(data[0].Departure.Date).format("ddd, MMM DD, YYYY");
    },
    getStop(data) {
      return data.length - 1;
    },
    getflightname(data) {
      return data[0].MarketingCarrier.Name;
    },

    getlogo($event) {
      return $event[0].MarketingCarrier.logo;
    },
    getlogo1($event) {
      return $event[0].MarketingCarrier.logo;
    },

    getflightnumber(data) {
      return data[0].MarketingCarrier.FlightNumber;
    },

    getflightid(data) {
      return data[0].MarketingCarrier.AirlineID;
    },
    depairname(data) {
      return data[0].Departure.AirportName;
    },
    depairtime(data) {
      return moment(data[0].Departure.Time, "HH:mm:ss").format("HH:mm");
    },
    arrAirname(data) {
      return data[data.length - 1].Arrival.AirportName;
    },
    arrAirtime(data) {
      return moment(data[data.length - 1].Arrival.Time, "HH:mm:ss").format(
        "HH:mm"
      );
    },

    // ----return --------------

    getstart(data) {
      return data[0].Departure.AirportCode;
      // console.log(data[0].Departure.AirportCode, 'kkkkkkk...q.e.we.e.e.')
    },
    getend(data) {
      return data[data.length - 1].Arrival.AirportCode;
    },
    getstartdate(data) {
      return moment(data[0].Departure.Date).format("ddd, MMM DD, YYYY");
    },
    getstop1(data) {
      return data.length - 1;
    },

    getstartname(data) {
      return data[0].MarketingCarrier.Name;
    },

    getstartnumber(data) {
      return data[0].MarketingCarrier.FlightNumber;
    },

    getstartid(data) {
      return data[0].MarketingCarrier.AirlineID;
    },
    getdepname(data) {
      return data[0].Departure.AirportName;
    },
    getdeptime(data) {
      return moment(data[0].Departure.Time, "HH:mm:ss").format("HH:mm");
    },
    getarrname(data) {
      return data[data.length - 1].Arrival.AirportName;
    },

    getarrtime(data) {
      return moment(data[data.length - 1].Arrival.Time, "HH:mm:ss").format(
        "HH:mm"
      );
    },

    getdeparDate($event) {
      console.log($event, "$event$event$event$event");
      return moment($event[0].Departure.Date).format("ddd MMM DD");
    },

    getdeparAirportCode($event) {
      return $event[0].Departure.AirportCode;
    },

    getdeparArrivalcode($event) {
      return $event[$event.length - 1].Arrival.AirportCode;
    },

    getdated($event) {
      return moment($event).format("MMM DD");
    },

    getdated1($event) {
      return moment($event).format("MMM DD");
    },
    getflightdep(data) {
      return moment(data.Departure.Time, "HH:mm:ss").format("HH:mm");
    },
    getflightarr(data) {
      return moment(data.Arrival.Time, "HH:mm:ss").format("HH:mm");
    },

    reset() {
      (this.ffb = ""), (this.air = "");
    },
    reset1() {
      (this.Redress = ""), (this.Traveler = "");
    },
    added() {
      let result =
        this.adultData.length + this.childData.length + this.infrantData.length;
      if (this.paxContacts.length < result) {
        this.paxContacts.push({
          number: "",
          email: "",
        });
      }
    },
    delet(index) {
      this.paxContacts.splice(index, 1);
    },
    // add() {
    //     for (let i = 0; i < this.adultData.length; i++) {
    //         if (this.adultData.length < 1) {
    //             this.adultData.push({
    //                 fname: "",
    //                 last: "",
    //                 middle: "",
    //                 year: "",
    //                 date: "",
    //                 month: "",
    //                 gender: "",
    //                 salutation: "",
    //                 ffbnumber: false,
    //             })
    //         }
    //     }
    // },
    ffbdata(data, index) {
      console.log(index, "index");
      console.log(data, "12345");
      this.adultData.map((item, i) => {
        if (i == index) {
          item.ffbnumber = !item.ffbnumber;
        }

        return item;
      });
    },
    tsadata(index) {
      this.adultData.map((item, i) => {
        if (i == index) {
          item.TSAnumber = !item.TSAnumber;
        }

        return item;
      });
    },
    apidata(index) {
      this.apivalue = true;
      this.adultData.map((item, i) => {
        if (i == index) {
          item.Apinumber = !item.Apinumber;
        }

        return item;
      });
    },

    day() {
      for (let i = 1; i <= 31; i++) {
        this.dateData.push(i);
        // console.log(this.date, '9999999')
      }
    },
    year() {
      for (let i = 2023; i <= 2035; i++) {
        this.yearData.push(i);
        // console.log(this.yearData, '9999999')
      }
    },

    adultDob() {
      this.adultData.map((v) => {
        if (v.dateOfBirth) {
          console.log(v.dateOfBirth, "dobdob");

          v.clicked1 = true;
          v.dobAdultError = false;
          console.log(v.dobAdultError, "dobdob");
        } else {
          v.clicked1 = false;
          v.dobAdultError = true;
        }
      });
    },
    childDob() {
      this.childData.map((v) => {
        if (v.dateOfBirth) {
          v.clicked1 = true;
          this.dobChildError = false;
        } else {
          v.clicked1 = false;
          this.dobChildError = true;
        }
      });
    },
    infrantDob() {
      this.infrantData.map((v) => {
        if (v.dateOfBirth) {
          v.clicked1 = true;
          this.dobInfrantError = false;
        } else {
          v.clicked1 = false;
          this.dobInfrantError = true;
        }
      });
    },

    // ClickOutDepat() {
    //     this.$refs.dpRef1.closeMenu();

    //     if (this.oneway.dedate) {
    //         this.clicked1 = true;
    //     }
    //     else {
    //         this.clicked1 = false;
    //     }
    // },

    getData() {
      console.log(this.adult, "this.adultthis.adult");
      for (let i = 0; i <= this.adultData.length; i++) {
        if (this.adultData.length < this.adult) {
          this.adultData.push({
            fname: "",
            last: "",
            middle: "",
            gender: "Male",
            salutation: "Mr",
            dateDob: {
              year: null,
              month: null,
              date: null,
            },
            dateOfBirth: null,
            ffbnumber: false,
            TSAnumber: false,
            Apinumber: false,
            Type: "Adult",
            clicked1: false,
            dobAdultError: false,
            isYearValid: false,
          });
        } else {
          return this.adultData;
        }
      }
      console.log(this.adultData, "ssss");
    },

    getChild() {
      for (let i = 0; i <= this.childData.length; i++) {
        if (this.childData.length < this.child) {
          this.childData.push({
            fname: "",
            last: "",
            middle: "",
            gender: "Male",
            salutation: "Mstr",
            dateDob: {
              year: null,
              month: null,
              date: null,
            },
            dateOfBirth: null,
            ffbnumber: false,
            TSAnumber: false,
            Apinumber: false,
            Type: "Child",
            clicked1: false,
            isYearValid: false,
            // dobChildError: false,
          });
        } else {
          return this.childData;
        }
      }
    },

    getinfrant() {
      for (let i = 0; i <= this.infrantData.length; i++) {
        if (this.infrantData.length < this.inf) {
          this.infrantData.push({
            fname: "",
            last: "",
            middle: "",
            gender: "Male",
            salutation: "Mstr",
            dateDob: {
              year: null,
              month: null,
              date: null,
            },
            dateOfBirth: null,
            ffbnumber: false,
            TSAnumber: false,
            Apinumber: false,
            Type: "Infrant",
            clicked1: false,
            isYearValid: false,
            // dobInfrantError: false,
          });
        } else {
          return this.infrantData;
        }
      }
    },

    getadult() {
      this.showConfirmPage = true;
      this.roundresult.forEach((v) => {
        console.log(v, "tttooooo....");
        this.currency = v.currency;
        this.totalTaxPassanger = parseFloat(v.Taxprice).toFixed(2);
        this.totalbasePassanger = parseFloat(v.Baseprice).toFixed(2);
        this.totalAmountpassenger = parseFloat(v.TotalPrice).toFixed(2);
        this.adultLength = v.Adult;
        this.childLength = v.Child;
        this.infLength = v.Inf;
        this.adult = v.Adult;
        this.child = v.Child;
        this.inf = v.Inf;
      });
    },

    onewayData() {
      this.showConfirmPage = true;
      this.onewayresult.forEach((v) => {
        this.currency = v.currency;
        this.totalTaxPassanger = parseFloat(v.Taxprice).toFixed(2);
        this.totalbasePassanger = parseFloat(v.Baseprice).toFixed(2);
        this.totalAmountpassenger = parseFloat(v.TotalPrice).toFixed(2);
        this.adultLength = v.Adult;
        this.childLength = v.Child;
        this.infLength = v.Inf;
        this.adult = v.Adult;
        this.child = v.Child;
        this.inf = v.Inf;
      });
    },

    multiData() {
      this.showConfirmPage = true;
      let res = this.local_id.split("-");
      let res1 = res[res.length - 1];
      let data_pass = [];
      data_pass = JSON.parse(localStorage.getItem(`${"pass"}-${res1}`));

      this.adultLength = data_pass.adult;
      this.childLength = data_pass.child;
      this.infLength = data_pass.inf;
      this.adult = data_pass.adult;
      this.child = data_pass.child;
      this.inf = data_pass.inf;
      console.log(data_pass, "data_pass");

      let totalpass = [];
      totalpass = this.adultLength + this.childLength + this.infLength;
      console.log(totalpass, "totalpass");

      this.multiresult.forEach((v) => {
        this.currency = v.currency;
        this.totalTaxPassanger = parseFloat(v.Taxprice).toFixed(2);
        this.totalbasePassanger = parseFloat(v.Baseprice).toFixed(2);
        this.totalAmountpassenger = parseFloat(v.TotalPrice).toFixed(2);
      });
    },

    handleScroll() {
      if (
        this.lastPosition < window.scrollY &&
        this.limitPosition < window.scrollY
      ) {
        this.scrolled = true;
        this.topSticky = "5%";
      } else {
        this.scrolled = false;
        this.topSticky = "23%";
      }

      this.lastPosition = window.scrollY;
    },
    // fareinsert() {
    //   this.insertFare.hit_id = this.booking_Id

    // },
    async getFareRules() {
      let fareRequest = {
        request_type: "farerules",
        shopping_response_id: this.shoppingid,
        offer_id: this.selectId,
        portal_id: this.portal_id,
      };
      console.log(fareRequest, "fareRequestssssss");
      axios
        .get("https://dl.buymytrip.com/api/farerules", {
          headers: {
            "Content-Type": "text/html",
          },
          params: fareRequest,
        })
        .then((response) => {
          this.fareRulesContent = response.data;
          // console.log(response.data, "fareRulesContentfareRulesContent.....")
        })

        .catch((error) => {
          console.log(error, "erroroor.....");
        });
    },

    submitform() {
      console.log(
        this.dataLoader,
        this.Localoneway_id,
        "dataLoaderdataLoader...dataLoaderdataLoader"
      );
      // this.fareinsert();
      let contact = {
        contactCheck: this.contactCheck,
        couponsCheck: this.couponsCheck,
      };
      localStorage.setItem("Travelcontact", JSON.stringify(contact));
      this.passengersDatas.adultDatas = this.adultData;
      this.passengersDatas.childDatas = this.childData;
      this.passengersDatas.infrantDatas = this.infrantData;

      if (this.valid1 && this.paxContacts[0].number && this.formCheckBox) {
        console.log(this.valid1, "ggghghghghg.....");
        this.datenumber = true;

        if (this.portal) {
          this.dataLoader = false;

          let uniDataId = `${"env"}-${this.local_id}`;
          localStorage.setItem(
            `${uniDataId}`,
            JSON.stringify(this.portalResData)
          );
          if (this.roundresult.length > 0) {
            localStorage.setItem(
              `${"env"}-${this.local_id}`,
              JSON.stringify(this.portalResData)
            );

            this.$router.push({
              path: "/confirmBook",
              query: { pass_id: uniDataId },
            });
          } else if (this.onewayresult.length > 0) {
            localStorage.setItem(
              `${"env"}-${this.local_id}`,
              JSON.stringify(this.portalResData)
            );
            this.$router.push({
              path: "/confirmBook",
              query: { pass_id: uniDataId },
            });
          } else if (this.multiresult.length > 0) {
            localStorage.setItem(
              `${"env"}-${this.local_id}`,
              JSON.stringify(this.portalResData)
            );
            localStorage.setItem(
              "env-local_id",
              JSON.stringify(this.portalResData)
            );
            this.$router.push({
              path: "/confirmBook",
              query: { pass_id: uniDataId },
            });
          }
        } else {
          this.dataLoader = false;

          if (this.Localoneway_id) {
            this.$router.push({
              path: "/confirmBook",
              query: { pass_id1: this.Localoneway_id },
            });
          } else if (this.local_id.includes("multi")) {
            this.$router.push({
              path: "/confirmBook",
              query: { pass_id: this.local_id },
            });
          } else if (this.local_id) {
            this.$router.push({
              path: "/confirmBook",
              query: { pass_id: this.local_id },
            });
          } else {
            console("Not....working....");
          }
          // }, 3000);
        }
      } else {
        this.dobAdultError = true;
        this.dobChildError = true;
        this.dobInfrantError = true;
        this.datenumber = true;

        if (!this.formCheckBox) {
          this.checkForm = true;
        }

        return this.valid1;
      }

      localStorage.setItem(
        "TravelerDetails",
        JSON.stringify(this.passengersDatas)
      );
      localStorage.setItem(
        "passengerContact",
        JSON.stringify(this.paxContacts)
      );
    },

    async portelDataConvert() {
      let userBooking = {
        offer_id: this.portal_offId,
        shopping_response_id: this.portal_shopId,
        pos: "US",
        api_env: "CERT",
        request_type: "user_booking_view",
      };
      console.log(userBooking, this.bookingviewApi, "userBookinguserBooking");
      await axios
        .post(this.bookingviewApi, userBooking, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((responce) => {
          console.log(responce.data, "responce.dataresponce.data5555555555");
          if (responce.data.view_response == "fail") {
            setTimeout(() => {
              this.dialog1 = true;
            }, 3000);
            console.log("dialog1111");
          } else {
            let result = responce.data;
            this.portalData.push(result);
            console.log(this.portalData, "this.portalDatathis.portalData");
            this.checkOutPage = true;
            this.dataloader = false;
            this.showConfirmPage = true;
            console.log("dialog11114444444444444");
          }
        })
        .catch((err) => {
          console.log(err, "ppppp5555555");
          setTimeout(() => {
            this.dialog1 = true;
          }, 3000);
        });

      if (this.portalData.length > 0) {
        this.dataloader = false;
        for (let i = 0; i < this.portalData.length; i++) {
          console.log(this.portalData[i], "power");

          let Journey = [];
          let flightsegment = [];
          Journey = this.portalData[i].DataLists.FlightList.Flight;
          console.log(Journey, "Journey");
          flightsegment =
            this.portalData[i].DataLists.FlightSegmentList.FlightSegment;
          let shoppingId = this.portalData[i].ShoppingResponseId;
          // console.log(flightsegment, "flightsegment");
          let $data = [];
          $data.push(this.portalData[i].OffersGroup.AirlineOffers.Offer);

          let source1 = [];
          let source2 = [];
          let source3 = [];
          let source4 = [];
          let adult = 0;
          let child = 0;
          let inf = 0;
          $data.forEach((v) => {
            v.OfferItem[0].forEach((t) => {
              if (t.PassengerType == "ADT") {
                adult = t.PassengerQuantity;
              }
              if (t.PassengerType == "CNN") {
                child = t.PassengerQuantity;
              }
              if (t.PassengerType == "INF") {
                inf = t.PassengerQuantity;
              }
            });

            v.OfferItem[0][0].FareComponent.forEach((s, index) => {
              if (index == 0) {
                this.offerData1.push(s.SegmentRefs.split(" "));

                for (let i = 0; i <= Journey.length - 1; i++) {
                  if (s.SegmentRefs == Journey[i].SegmentReferences) {
                    source1.push(Journey[i].Journey);
                  }
                }
              }
              if (index == 1) {
                this.offerData2.push(s.SegmentRefs.split(" "));
                for (let i = 0; i <= Journey.length - 1; i++) {
                  if (s.SegmentRefs == Journey[i].SegmentReferences) {
                    source2.push(Journey[i].Journey);
                  }
                }
              }
              if (index == 2) {
                this.offerData3.push(s.SegmentRefs.split(" "));
                for (let i = 0; i <= Journey.length - 1; i++) {
                  if (s.SegmentRefs == Journey[i].SegmentReferences) {
                    source3.push(Journey[i].Journey);
                  }
                }
              }

              if (index == 3) {
                this.offerData4.push(s.SegmentRefs.split(" "));
                for (let i = 0; i <= Journey.length - 1; i++) {
                  if (s.SegmentRefs == Journey[i].SegmentReferences) {
                    source4.push(Journey[i].Journey);
                  }
                }
              }
            });
          });
          console.log(flightsegment, "this.flightsegment");

          if (this.offerData1.length > 0) {
            for (let i = 0; i < this.offerData1.length; i++) {
              let seg = [];
              this.offerData1[i].forEach((f, index) => {
                let items = [];
                items = flightsegment.filter((v) => v.SegmentKey == f);
                if (items.length > 0) {
                  seg.push(items[0]);
                }
                if (index == this.offerData1[i].length - 1) {
                  this.portalResData.push({ Depature: seg });
                  this.Flight1.push(seg);
                }
              });
            }
          }

          if (this.offerData2.length > 0) {
            for (let i = 0; i < this.offerData2.length; i++) {
              let seg = [];
              this.offerData2[i].forEach((s, index) => {
                let item = [];
                item = flightsegment.filter((v) => v.SegmentKey == s);
                if (item.length > 0) {
                  seg.push(item[0]);
                }
                if (index == this.offerData2[i].length - 1) {
                  this.Flight2.push(seg);
                }
              });
            }
          }
          // console.log(this.offerData3, "this.offerData3this.offerData3");
          if (this.offerData3.length > 0) {
            for (let i = 0; i < this.offerData3.length; i++) {
              let seg = [];
              this.offerData3[i].forEach((s, index) => {
                let item = [];
                item = flightsegment.filter((v) => v.SegmentKey == s);
                if (item.length > 0) {
                  seg.push(item[0]);
                }
                if (index == this.offerData3[i].length - 1) {
                  this.Flight3.push(seg);
                }
              });
            }
          }

          if (this.offerData4.length > 0) {
            for (let i = 0; i < this.offerData4.length; i++) {
              let seg = [];
              this.offerData4[i].forEach((s, index) => {
                let item = [];
                item = flightsegment.filter((v) => v.SegmentKey == s);
                if (item.length > 0) {
                  seg.push(item[0]);
                }
                if (index == this.offerData4[i].length - 1) {
                  this.Flight4.push(seg);
                }
              });
            }
          }

          for (let i = 0; i < this.airlogodata.length - 1; i++) {
            for (let j = 0; j < flightsegment.length - 1; j++) {
              if (
                flightsegment[j].MarketingCarrier.AirlineID ==
                this.airlogodata[i].id
              ) {
                flightsegment[j].MarketingCarrier.logo =
                  this.airlogodata[i].logo;
              }
            }
          }

          let currency = [];
          let Baseprice = [];
          let TotalPrice = [];
          let Tax = [];
          let perperson = [];
          let ownername = [];
          let ownerlogo = [];
          let offerId = [];
          let cabinType = [];

          console.log($data, "ppppppppppkeerthi");

          $data.forEach((v, i) => {
            console.log(v, i, "offeriddatataa");
            offerId.push(v.OfferID);
          });

          $data.forEach((s) => {
            if (s.ReqCurrency == "USD") {
              currency.push("US$");
            } else if (s.ReqCurrency == "CAD") {
              currency.push("CA$");
            } else {
              currency.push("US$");
            }
            ownername.push(s.OwnerName);
            for (let i = 0; i < this.airlogodata.length - 1; i++) {
              if (s.Owner == this.airlogodata[i].id) {
                ownerlogo.push(this.airlogodata[i].logo);
              }
            }

            if (s.CabinType) {
              cabinType =
                s.CabinType.charAt(0).toUpperCase() +
                s.CabinType.slice(1).toLowerCase();
            } else {
              cabinType =
                this.portalData[i].SearchClass.charAt(0).toUpperCase() +
                this.portalData[i].SearchClass.slice(1).toLowerCase();
            }
            Baseprice.push(s.BasePrice.BookingCurrencyPrice);
            Tax.push(s.TaxPrice.BookingCurrencyPrice);
            perperson.push(s.PerPerson.BookingCurrencyPrice);
            TotalPrice.push(s.TotalPrice.BookingCurrencyPrice);
          });

          console.log(cabinType, "cabinTypecabinTypecabinType");

          for (let i = 0; i < this.portalResData.length; i++) {
            this.portalResData[i].selectId = offerId[i];
            this.portalResData[i].currency = currency[i];
            this.portalResData[i].Baseprice = Baseprice[i];
            this.portalResData[i].TotalPrice = TotalPrice[i];
            this.portalResData[i].Taxprice = Tax[i];
            this.portalResData[i].perperson = perperson[i];
            this.portalResData[i].Return = this.Flight2[i];
            this.portalResData[i].Flight1 = this.Flight1[i];
            this.portalResData[i].Flight2 = this.Flight2[i];
            this.portalResData[i].Flight3 = this.Flight3[i];
            this.portalResData[i].Flight4 = this.Flight4[i];
            this.portalResData[i].ownerlogo = ownerlogo[i];
            this.portalResData[i].ownername = ownername[i];
            this.portalResData[i].Depaturejourney = source1[i];
            this.portalResData[i].Returnjourney = source2[i];
            this.portalResData[i].Flight1JourneyTime = source1[i];
            this.portalResData[i].Flight2JourneyTime = source2[i];
            this.portalResData[i].Flight3JourneyTime = source1[i];
            this.portalResData[i].Flight4JourneyTime = source2[i];
            this.portalResData[i].cabinClass = cabinType;
            this.portalResData[i].adult = adult;
            this.portalResData[i].child = child;
            this.portalResData[i].inf = inf;
            this.portalResData[i].ShoppingResponseId = shoppingId;
          }

          console.log(
            this.portalResData,
            "this.portalResDatathis.portalResData..."
          );
          if (this.offerData3.length > 0) {
            this.multiresult = this.portalResData;
            // this.itineraryInfo = true;
          } else if (this.offerData2.length > 0) {
            this.roundresult = this.portalResData;
            this.itineraryInfo = true;
          } else if (this.offerData1.length > 0) {
            this.onewayresult = this.portalResData;
            this.itineraryInfo1 = true;
          }

          this.portalResData.forEach((v) => {
            console.log(v, "ppppp");
            this.local_id = v.selectId;
            this.currency = v.currency;
            this.adult = parseFloat(v.adult);
            console.log(this.adult, "pppp");
            this.adultLength = parseFloat(v.adult);
            this.childLength = parseFloat(v.child);
            this.child = parseFloat(v.child);
            this.inf = parseFloat(v.inf);
            this.infLength = parseFloat(v.inf);
            this.totalTaxPassanger = parseFloat(v.Taxprice).toFixed(2);
            this.totalbasePassanger = parseFloat(v.Baseprice).toFixed(2);
            this.totalAmountpassenger = parseFloat(v.TotalPrice).toFixed(2);
          });
          this.getData();
          this.getChild();
          this.getinfrant();
          console.log(
            this.portalResData,
            "this.portalResDatathis.portalResData"
          );
        }
      } else {
        console.log("flightdata");
      }
    },

    getConfig() {
      const getConfigData1 = getConfigDataFromLocalStorage();
      if (getConfigData1) {
        this.bookingviewApi =
          getConfigData1.payload.portal_configuration.API_endpoints.meta_get_itinerary;

        this.displayCurrency =
          getConfigData1.payload.portal_configuration.currency.display;
        this.metaCurrency =
          getConfigData1.payload.portal_configuration.currency.iso_code;
        this.portal_id = getConfigData1.payload.portal_configuration.portal_id;
        this.pointScale =
          getConfigData1.payload.portal_configuration.pointofsale;
        console.log(
          getConfigData1,
          "getConfigData1getConfigData1getConfigData1"
        );
      }
    },

    getseatMapping() {
      // console.log("seatmapping");
      let $data = [];
      let triptype = "";
      let seat = {
        request_type: "seatmap",
        offer_id: "",
        shopping_response_id: "",
        hit_id: "",
        search_cabin_type: "",
        trip_type: "",
        pointofsale: this.pointScale,
        adult: "",
        child: "",
        paxInfant: "",
      };

      if (this.roundresult.length > 0) {
        $data = this.roundresult;
        triptype = "RoundTrip";
        // console.log(this.roundresult, "seatmapping");
      } else if (this.onewayresult.length > 0) {
        $data = this.onewayresult;
        triptype = "OneWay";
      } else if (this.multiresult.length > 0) {
        $data = this.multiresult;
        triptype = "MultiCity";
      }
      seat.shopping_response_id = $data[0].ShoppingResponseId;
      seat.offer_id = $data[0].selectId;
      seat.hit_id = $data[0].hitId;
      seat.search_cabin_type = $data[0].cabinClass;
      seat.pointofsale = "IN";
      seat.adult = $data[0].Adult;
      seat.child = $data[0].Child;
      seat.paxInfant = $data[0].Inf;
      seat.trip_type = triptype;

      axios
        .post("https://dl.buymytrip.com/api/seatmapdata", seat, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((responce) => {
          console.log(responce, "seatmapdata");
          this.seatSelect = responce.data.data;
          console.log(this.seatSelect,'this.seatSelect')
          this.getseatselect();
        })
        .catch((err) => {
          console.log(err, "ppppp5555555");
        });

      console.log(seat, "kkkk3333333333");
    },

    getseatselect() {
      this.totalpassanger =
        parseFloat(this.adultLength) +
        parseFloat(this.childLength) +
        parseFloat(this.infLength);
      console.log(this.totalpassanger, "this.totalpassanger");
      let $event = [];
      this.seatSelect.forEach((v, i) => {
        console.log(v, i, "keerthikannan123");
        this.seatSelect[i].forEach((c, j) => {
          console.log(c, j, "vasanthaganesh");
          if (c.cabins.length > 0) {
            console.log(c, j, "vasanthaganesh666666666666666");
            // this.totalSeat
            $event.push(c);
          }
        });
      });
      this.totalSeat = $event;
      console.log(this.totalSeat, "this.totalSeat");
      //  let arrayData = []; // You can remove this line as it's not being used

      if (this.totalSeat.length > 0) {
        this.totalSeat[0].cabins.forEach((c) => {
          console.log(c, "ppppppppppppppppp");
          let arrayData = [];
          arrayData = c.columns;

          // console.log(arrayData, "arrayData");
          // console.log(c.rows, "rowsrows");
          let data = 0;
          arrayData.forEach((a, l) => {
            console.log(a, "rowsrows");
            if (a.type == "AisleA") {
              data = data + 1;
              if (data == 2) {
                arrayData[l - 1].space = true;
                arrayData[l].space = false;
              }
            } else {
              data = 0;
              arrayData[l].space = false;
            }
            c.seat_rows.forEach((d) => {
              if (d.seats[0].seat_number == a.column) {
                console.log(this.totalData , d,a.column,'lololol')

                if (!this.totalData[a.column]) {
                  this.totalData[a.column] = [];
                }

                this.totalData[a.column].push(d);
              }
            });
            this.totalData[a.column].forEach((w) => {
              w.color = false;
              console.log(w, "dddwwwwdddd");
            });
            // this.totalData[a.column].forEach
          });
          console.log(arrayData, "arrayData");
        });
        console.log(this.totalData, "$data$data$data");
      }
    },
    selectdata(data, index) {
      console.log(data, index);
      this.passSelect = 0;
      this.indexData = index;
      this.totalData = [];
      let falData = false;
      if (this.totalSeat.length > 0) {
        this.totalSeat[index].cabins.forEach((c, k) => {
          c.seat_rows[k].color = falData[k];

          let arrayData = [];
          arrayData = c.columns;
          let data = 0;
          arrayData.forEach((a, l) => {
            if (a.type == "AisleA") {
              data = data + 1;
              if (data == 2) {
                arrayData[l - 1].space = true;
                arrayData[l].space = false;
              }
            } else {
              data = 0;
              arrayData[l].space = false;
            }

            c.seat_rows.forEach((d) => {
              // console.log(d, "rowsrows");
              if (d.seats[0].seat_number == a.column) {
                if (!this.totalData[a.column]) {
                  this.totalData[a.column] = [];
                }
                this.totalData[a.column].push(d);
              }
            });

            // this.totalData[a.column].forEach((w) => {
            //   console.log(w, "dddwwwwdddd");
            // });
          });
        });
      }
    },
    seatSelectdata(datas, index1, index) {
      // console.log(datas, index1, index, "datas, index1");
      // console.log(this.totalpassanger, this.passSelect, "lo99999lololo");

      if (this.passSelect + 1 < this.totalpassanger) {
        // console.log(this.totalpassanger - 1, this.passSelect, "lolololo");
        this.passSelect = parseFloat(this.passSelect) + 1;
      }

      this.totalData[index].map((c, i) => {
        if (i == index1) {
          c.color = !c.color;
        }
      });
    },

    totalpassData(data, index) {
      console.log(data, index, "data, index");
      this.passSelect = index;
    },
  },

  watch: {
    adultData: {
      deep: true,
      handler(newData) {
        if (newData) {
          // console.log(newData, 'newDatanewDatanewDatanewDatanewDatanewData')
          newData.forEach((data) => {
            // data.gender = data.salutation === 'Mr' ? this.$t('flightInfoContent.labels.male') : this.$t('flightInfoContent.labels.female');
            if (data.salutation !== "Mr") {
              data.gender = "Female";
            } else {
              data.gender = "Male";
            }

            if (data.dateDob.date) {
              // console.log(data.dateOfBirth.date,'newDatanewData')
              if (
                data.dateDob.year &&
                data.dateDob.month &&
                data.dateDob.date
              ) {
                const monthIndex = this.monthList.indexOf(data.dateDob.month);
                const convertDob = new Date(
                  parseInt(data.dateDob.year),
                  monthIndex,
                  parseInt(data.dateDob.date)
                );

                const formatDate = moment(convertDob).format("YYYY-MMM-DD");

                data.dateOfBirth = formatDate;

                // console.log(data.dateOfBirth, 'data.dateOfBirthdata.dateOfBirth')
              }
            }
          });
        }
      },
    },

    childData: {
      deep: true,
      handler(newData) {
        if (newData) {
          newData.forEach((data) => {
            if (data.salutation !== "Mstr") {
              data.gender = "Female";
            } else {
              data.gender = "Male";
            }

            if (data.dateDob.date) {
              // console.log(data.dateOfBirth.date,'newDatanewData')
              if (
                data.dateDob.year &&
                data.dateDob.month &&
                data.dateDob.date
              ) {
                const monthIndex = this.monthList.indexOf(data.dateDob.month);
                const convertDob = new Date(
                  parseInt(data.dateDob.year),
                  monthIndex,
                  parseInt(data.dateDob.date)
                );

                const formatDate = moment(convertDob).format("YYYY-MMM-DD");

                data.dateOfBirth = formatDate;

                // console.log(data.dateOfBirth, 'data.dateOfBirthdata.dateOfBirth')
              }
            }
          });
        }
      },
    },

    infrantData: {
      deep: true,
      handler(newData) {
        newData.forEach((data) => {
          if (data.salutation !== "Mstr") {
            data.gender = "Female";
          } else {
            data.gender = "Male";
          }

          if (data.dateDob.date) {
            // console.log(data.dateOfBirth.date,'newDatanewData')
            if (data.dateDob.year && data.dateDob.month && data.dateDob.date) {
              const monthIndex = this.monthList.indexOf(data.dateDob.month);
              const convertDob = new Date(
                parseInt(data.dateDob.year),
                monthIndex,
                parseInt(data.dateDob.date)
              );

              const formatDate = moment(convertDob).format("YYYY-MMM-DD");

              data.dateOfBirth = formatDate;

              // console.log(data.dateOfBirth, 'data.dateOfBirthdata.dateOfBirth')
            }
          }
        });
      },
    },

    "data.number"(newValue) {
      console.log(newValue, "ndnjdnjdnjnd");

      if (newValue.length <= 12 && newValue.length > 5) {
        this.data.number = newValue.slice(0, 12);
      }
    },

    // "data.number"(newValue) {

    //   if (newValue.length <= 12 && newValue.length >5) {
    //     this.data.number = newValue.slice(0, 12);
    //   }
    // },
  },

  mounted() {
    this.getseatMapping();
    country1.forEach((v) => {
      let obj = {
        name: `${v.name}(${v.dial_code})`,
        code: v.code,
      };
      this.countrycode.push(obj);
    });

    let result1 = localStorage.getItem("value1");
    console.log(result1, "123245");

    // console.log(this.dateOfBirth, "dobdob.....");

    this.getData();
    this.getChild();
    this.getinfrant();
  },

  created() {
    this.getConfig();

    let $data = localStorage.getItem("loader");
    if ($data == "true") {
      console.log("working....11111");
      this.$router.push("/");
    }

    this.local_id = this.$route.query.pass_uid;
    this.dataLink = location.href;
    this.Localoneway_id = this.$route.query.pass_uid1;
    console.log(this.Localoneway_id, "this.local_idthis.local_id888888888888");
    // console.log(this.dataLink, "data_idthisdata8");

    //     if (!this.$route.query.pass_uid) {

    // }

    if (this.dataLink.includes("env")) {
      if (this.dataLink.includes("env")) {
        this.portal = true;
        this.portal_offId = this.$route.query.pass_uid;
        this.portal_shopId = this.$route.query.response_id;
        this.airlogodata = airLogo;
        this.portelDataConvert();
        this.checkOutPage = false;
        setTimeout(() => {
          this.getsegment();
        }, 2000);
      }
    } else if (this.Localoneway_id) {
      let onewaydata_uid = [];
      onewaydata_uid = JSON.parse(
        localStorage.getItem(`${this.Localoneway_id}`)
      );

      if (onewaydata_uid) {
        this.onewayresult.push(onewaydata_uid);
        this.dataloader = this.onewayresult ? false : true;
        this.onewayData();
        this.itineraryInfo1 = true;
        this.showConfirmPage = true;

        console.log(this.onewayresult, "onewayresult.....onewayresult");

        setTimeout(() => {
          this.getsegment();
        }, 2000);
      } else if (this.Localoneway_id !== onewaydata_uid) {
        setTimeout(() => {
          this.showConfirmPage = false;
          this.dialog2 = true;
        }, 5000);
      }
    } else if (this.local_id) {
      if (this.local_id.includes("multi")) {
        let data_uid = [];
        data_uid = JSON.parse(localStorage.getItem(`${this.local_id}`));
        if (data_uid) {
          this.multiresult.push(data_uid);
          this.dataloader = this.multiresult ? false : true;
          this.multiData();
          this.showConfirmPage = true;
          console.log(data_uid, "multicitymulticitymulticity");
          setTimeout(() => {
            this.getsegment();
          }, 2000);
        }
      } else {
        let data_uid = [];
        data_uid = JSON.parse(localStorage.getItem(`${this.local_id}`));

        if (data_uid) {
          this.roundresult.push(data_uid);
          this.dataloader = this.roundresult ? false : true;
          this.getadult();
          this.itineraryInfo = true;
          this.showConfirmPage = true;

          setTimeout(() => {
            this.getsegment();
          }, 2000);
        } else if (this.local_id !== data_uid) {
          console.log("dialog1dialog1dialog2");

          setTimeout(() => {
            this.showConfirmPage = false;
            this.dialog2 = true;
          }, 5000);
        }
      }
    } else {
      // console.log('redirectingggg...')
      this.$router.push("/");
      // setTimeout(() => {
      //   console.log('dialog1dialog1dialog2')
      //         this.showConfirmPage = false;
      //         this.dialog1 = true;
      //       }, 5000);
    }
    if (this.roundresult.length > 0) {
      this.shoppingid = this.roundresult[0].ShoppingResponseId;
      this.selectId = this.roundresult[0].selectId;
    } else if (this.onewayresult.length > 0) {
      this.shoppingid = this.onewayresult[0].ShoppingResponseId;
      this.selectId = this.onewayresult[0].selectId;
    } else if (this.multiresult.length > 0) {
      this.shoppingid = this.multiresult[0].ShoppingResponseId;
      this.selectId = this.multiresult[0].selectId;
    }
  },
};
</script>

<style scoped>
.f-size-14 {
  font-size: 14px;
}

.fs-12 {
  font-size: 12px;
}

.fw-500 {
  font-weight: 500;
}

.fw-400 {
  font-weight: 400;
}

>>> .v-card {
  overflow: unset;
  position: inherit !important;
}

>>> .v-input__details {
  display: block;
}

>>> .v-messages__message {
  font-size: 10px;
  line-height: 8px;
}

/* Data of Birth Section  */
.datePickerSection .yearPick,
.monthPick,
.datePick {
  width: 160px;
}

>>> .datePickerSection .yearPick .v-field {
  border-radius: 0 !important;
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}

>>> .datePickerSection .monthPick .v-field {
  border-radius: 0 !important;
}

>>> .datePickerSection .datePick .v-field {
  border-radius: 0 !important;
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

/* Data of Birth Section  */

.vue-tel-input {
  height: 40px;
}

.flight-btn1 {
  font-size: 16px;
  font-family: serif;
  font-weight: 400;
  color: black;
  cursor: pointer;
}

.p-error {
  font-size: 10px;
  color: #b00020;
  letter-spacing: 0.0333333333em;
  transition-duration: 150ms;
  position: relative;
  top: -5px;
}

>>> .spl-checkbox .v-label {
  font-size: 12px !important;
  font-weight: 600;
}

.active-line1 {
  height: 3px;
  background-color: red;
  width: 82.2px;
  position: absolute;
  left: 35px;
  border-radius: 90px 0px 0px 40px/14px 0px 0px 8px;
}

.active-line2 {
  height: 3px;
  background-color: red;
  width: 99px;
  position: absolute;
  left: 117.2px;
  border-radius: 0px 90px 40px 0px/0px 14px 8px 0px;
}

/* >>>.v-img__img {
z-index: -1;
position: absolute;
top: 0;
left: 0;
width: 70%;
height: 50%;
} */
.v-card {
  border-radius: 10px;
}

.active1 {
  background-color: rgb(153, 190, 202);
  border-radius: 30px 0px 0px 30px;
  color: white;
}

.active2 {
  background-color: rgb(153, 190, 202);
  border-radius: 0px 30px 30px 0px;
  color: white;
}

/* Scroll Bar Styles */

.card-content::-webkit-scrollbar {
  width: 20px;
}

>>> .v-toolbar__content {
  height: 30px !important;
  padding: 2px 3px;
}

.card-content::-webkit-scrollbar-thumb {
  background: #4e4e4e;
  border-radius: 25px;
}

/* minimalist design */
.card-content::-webkit-scrollbar {
  width: 10px;
}

.card-content::-webkit-scrollbar-track {
  background: rgb(179, 177, 177);
  border-radius: 10px;
}

.card-content::-webkit-scrollbar-thumb {
  background: rgb(136, 136, 136);
  border-radius: 10px;
}

/* striped lines */
.card-content::-webkit-scrollbar {
  width: 20px;
}

.card-content::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 5px;
}

/* animated  */
.card-content::-webkit-scrollbar {
  width: 8px !important;
}

.card-content::-webkit-scrollbar-track {
  background: darkblue;
}

.card-content::-webkit-scrollbar-thumb {
  background: lightgray;
}

/* Scroll Bar Styles End */

.card-content {
  max-height: 540px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 10px;
}

.flight-btn {
  cursor: pointer;
  background-color: blueviolet;
  color: white;
}

p.small-size {
  font-size: 14px;
}

.font-weight {
  font-weight: 500;
}

.line-height {
  padding-bottom: 15px;
}

.f-size-16 {
  font-size: 16px;
}

.f-size-14 {
  font-size: 14px;
}

.f-size-13 {
  font-size: 13px;
}

.f-size-12 {
  font-size: 12px;
}

.f-size-11 {
  font-size: 11px;
}

.f-size-10 {
  font-size: 10px;
}

.f-size-9 {
  font-size: 9px;
}

.fw-500 {
  font-weight: 500;
}

.tool-span span {
  font-size: 18px;
  font-weight: 600;
}

.travel-date {
  font-size: 14px;
  font-weight: 600;
}

.dialog-bottom-transition-enter-active,
.dialog-bottom-transition-leave-active {
  transition: transform 0.2s ease-in-out;
}

.close-btn {
  min-width: 12%;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.reset-btn {
  color: brown;
  cursor: pointer;
  width: 100px;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0px;
  box-shadow: none;
  text-transform: capitalize;
}

.addmore-btn {
  color: dodgerblue;
  cursor: pointer;
  width: 100px;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0px;
  box-shadow: none;
  text-transform: capitalize;
}

.addmore-btn:hover {
  color: darkcyan;
}

>>> .v-selection-control .v-label {
  white-space: normal;
  word-break: break-word;
  height: 100%;
  width: 100%;
  font-size: 14px;
}

/* Timeline */

>>> .v-timeline-divider__dot--size-large {
  height: 22px;
  width: 56px;
}

>>> .v-timeline-divider__inner-dot {
  border-radius: 15px;
}

>>> .v-timeline-divider__inner-dot span {
  font-size: 11px;
  font-weight: 600;
}

.v-timeline--vertical.v-timeline {
  height: auto;
}

.card-space {
  margin: 5px 0px;
}

.fare-summery {
  position: sticky;
  padding: 10px 0px;
  width: 100%;
  z-index: 1;
  transition: top 0.5s;
}

.fare-summery-btn {
}

.ffb-button {
  color: #0748ac !important;
  border: 1px solid #0748ac !important;
  margin-left: 10px;
  height: 40px !important;
}

.ffb-button:hover {
  color: #fff !important;
  background: #0b446d !important;
  border: 1px solid #0b446d;
}

.ffb-button:focus {
  color: #fff !important;
  background: #0b446d !important;
  border: 1px solid #0b446d;
}

.ffb-button:active {
  color: #fff !important;
  background: #0b446d !important;
  border: 1px solid #0b446d;
}

.ffb-cart {
  box-shadow: none;
  background-color: #f6f6f6;
  padding: 10px 20px !important;
  margin-top: 10px;
}

.redress {
  cursor: pointer;
  color: #ee2b34;
}

.redress:hover {
  cursor: pointer;
  color: #0b446d;
}

.v-input__control {
  height: 45px !important;
}

.v-text-field .v-field--active input {
  opacity: 1;
  font-size: 13px;
  font-weight: 500;
  position: relative;
  top: -6px;
}

.v-field--center-affix .v-label.v-field-label {
  top: 39%;
  transform: translateY(-50%);
}

.v-selection-control .v-label {
  height: auto;
}

/* Datepicker Style Section */

.v-card {
  overflow: unset;
}

>>> .dp__input_icon {
  display: none;
}

.v-icon {
  width: 100%;
}

>>> .dp__input {
  height: 42px;
  border-color: darkgrey;
  border-radius: 4px !important;
  padding: 0px 20px;
}

>>> .dp__overlay_col[aria-disabled="true"] {
  display: none;
}

.mdi-calendar {
  margin-right: 10px;
  width: auto;
  color: grey;
}

>>> .dp__menu_inner {
  padding: var(--dp-menu-padding);
  box-shadow: 0px 0px 4px 0px black;
  background-color: #f5f5f521;
}

.calendar-container {
  display: flex;
  align-items: center;
  position: relative;
}

.label-top {
  color: transparent;
  background: transparent;
}

.datepicker-label {
  position: relative;
  top: 10px;
  left: 6px;
  transform: translateY(-100%);
  background-color: transparent;
  padding: 0 8px;
  font-size: 11px;
  color: gray;
  /* transition: all 0.3s ease-out; */
  pointer-events: none;
  z-index: 1;
  background: white;
}

.label-top2 {
  position: absolute;
  top: 0px;
  left: -8px;
  transform: translateY(-100%);
  background-color: transparent;
  padding: 0 8px;
  font-size: 12px;
  color: gray;
  /* transition: all 0.3s ease-out; */
  pointer-events: none;
}

.datepicker-label2 {
  position: absolute;
  top: 24px;
  left: -8px;
  transform: translateY(-100%);
  background-color: transparent;
  padding: 0 8px;
  font-size: 1rem;
  color: gray;
  /* transition: all 0.3s ease-out; */
  pointer-events: none;
  z-index: 1;
}

.multiLabel-top {
  position: absolute;
  right: 87px;
  transform: translateY(-100%);
  background-color: white;
  padding: 0 8px;
  font-size: 12px;
  color: #999;
  /* transition: all 0.3s ease-out; */
  pointer-events: none;
}

.multiDatepicker-label {
  position: absolute;
  right: 69px;
  background-color: transparent;
  padding: 0 8px;
  font-size: 1rem;
  color: #999;
  /* transition: all 0.3s ease-out; */
  pointer-events: none;
  z-index: 1;
}

>>> .v-input__control {
  height: 40px;
  display: flex;
  grid-area: control;
}

>>> .dp__input {
  height: 40px;
  border-color: darkgray;
}

>>> .v-input__control {
  height: 40px;
}

>>> .v-field__field {
  height: 40px;
}

>>> .v-field__input {
  padding-top: 0 !important;
}

.delete-icon {
  display: flex;
  justify-content: end;
  position: absolute;
  left: 12px;
  z-index: -1;
}

.p-float-label-1 {
  position: relative;
  top: -4px;
}

.p-float-label-2 {
  position: relative;
  top: -8px;
}

.dob-label-top {
  position: relative;
  pointer-events: none;
  top: 6px;
  left: 12px;
  margin-top: -0.5rem;
  transition-property: all;
  transition-timing-function: ease;
  line-height: 1;
  font-size: 12px;
  color: gray;
  z-index: 1;
  background: white;
  padding: 0px 3px;
  font-weight: 400;
}

.dob-label {
  position: relative;
  pointer-events: none;
  top: 27px;
  left: 18px;
  margin-top: -0.5rem;
  transition-property: all;
  transition-timing-function: ease;
  line-height: 1;
  font-size: 1rem;
  color: gray;
  z-index: 1;
  background: white;
  /* padding: 0px 3px; */
  font-weight: 400;
}

.dob-label-1 {
  visibility: hidden;
}

/* Timeline End */

.tot-price {
  font-size: 18px;
  font-weight: 600;
}

@media only screen and (min-width: 768px) {
  .fare-show {
    display: none;
  }

  .continue-btn {
    display: none;
  }
}
@media only screen and (max-width: 768px) {
  .fare-show {
    display: flex !important;
    justify-content: center;
  }
  .fare-show v-card {
    max-width: 0px;
  }
  >>> .spl-checkbox .v-input__details {
    display: none;
  }
}
@media screen and (max-width: 992px) {
  .fare-show {
    display: block;
  }
}

@media only screen and (max-width: 769px) {
  .fare-summery {
    display: none;
  }
  .fare-summery1 {
    display: none;
  }
  .agree-content {
    font-size: 11px;
  }

  .card-space {
    margin: 2px;
  }

  .font-change-14 {
    font-size: 14px;
  }

  .font-change-11 {
    font-size: 11px;
  }

  .layover-size {
    width: 50% !important;
  }

  .lay-fsize {
    font-size: 9px;
  }
}

@media only screen and (max-width: 767px) {
  .class-box {
    justify-content: flex-end;
  }
}

@media only screen and (max-width: 1200px) {
  .tot-price {
    font-size: 16px;
    font-weight: 600;
  }

  .lay-fsize {
    font-size: 9px;
  }
}

@media only screen and (max-width: 768px) {
  .price .price-content {
    text-align: left !important;
  }

  .card-box .spaced {
    padding: 5px 12px !important;
  }

  .tool-span span {
    font-size: 12px;
    font-weight: bold;
    padding: 0px 3px;
  }

  .sub-section {
    border-left: none !important;
  }

  .sub-section.row {
    padding-left: 0px !important;
  }

  .tot-price {
    font-size: 18px;
    font-weight: 500;
  }

  .airline-logo {
    padding: 0;
  }

  .airline-logo .v-img {
    width: 20px !important;
  }

  .airline-profile {
    padding: 0;
  }

  .airline-name {
    font-size: 8px;
  }

  .airline-id {
    font-size: 6px;
  }

  .aircode-name {
    font-size: 11px;
  }

  .airline-date {
    font-size: 8px;
  }

  .airline-time {
    font-size: 10px;
  }

  .class-type .v-icon {
    font-size: 20px !important;
  }

  .class-type span {
    font-size: 12px;
  }
}

>>> .v-autocomplete--single .v-field--variant-outlined input {
  top: 35%;
}
>>> .cancellationRules .fareRules_content__1uPa7 .FareRule h2 {
  font-size: 17px !important;
  font-weight: 700 !important;
}
>>> .cancellationRules .fareRules_content__1uPa7 .FareRule p {
  overflow-wrap: break-word !important;
  inline-size: 58%;
}
>>> .cancellationRules .fareRules_content__1uPa7 .FareRule ul li {
  overflow-wrap: break-word !important;
  inline-size: 60%;
}
>>> .drawer-section .v-slide-group__content {
  display: flex;
  justify-content: center !important;
}

>>> .v-slide-group__content .v-btn {
  height: 45px !important;
}

>>> .v-slide-group__content .v-btn__content {
  font-size: 12px;
}

>>> .drawer-section .v-slide-group__content {
  background: #f3f1f1;
  height: 50px;
}

>>> .drawer-section
  .v-slide-group__content
  .v-slide-group-item--active
  .v-btn__content {
  color: #002d5b;
}
>>> .v-tabs--density-default.v-tabs--stacked {
  --v-tabs-height: 58px;
}
>>> .v-tabs--fixed-tabs .v-slide-group__content > *:last-child,
.v-tabs--align-tabs-center .v-slide-group__content > *:last-child {
  margin-inline-end: inherit;
}
.stopsText {
  font-size: 14px;
}
.totalPrice {
  font-size: 26px;
}
@media screen and (max-width: 430px) {
  .drawer-section .offcanvas {
    width: 100% !important;
  }
  .stopsText {
    font-size: 11px;
    text-align: end;
  }
  .totalPrice {
    font-size: 19px;
  }
}

.drawer-section .offcanvas {
  width: 35%;
}

>>> .drawer-section .v-timeline--vertical.v-timeline {
  grid-row-gap: 24px !important;
}
>>> .drawer-section .v-timeline {
  justify-content: left !important;
}
>>> .drawer-section .v-list-item__content {
  display: flex !important;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.offcanvas-header {
  background: #5293cb !important;
  color: white !important;
}
>>> .btn-icon .v-btn--size-default {
  --v-btn-height: 11px !important;
}
.drawer-section .text-reset:hover {
  color: red !important;
  background: white;
  border-radius: 8px;
}

>>> .drawer-section .v-slide-group__content {
  display: flex;
  justify-content: space-evenly;
}
>>> .drawer-section .v-slide-group__content .v-btn {
  height: 45px !important;
}
>>> .drawer-section .v-slide-group__content .v-btn__content {
  font-size: 12px;
}
>>> .drawer-section .v-slide-group__content {
  background: #f3f1f1;
  height: 50px;
}
>>> .drawer-section
  .v-slide-group__content
  .v-slide-group-item--active
  .v-btn__content {
  color: #002d5b !important;
}
>>> .drawer-section .v-timeline-divider__inner-dot {
  background: #002d5b !important;
}
>>> .drawer-section .v-timeline--vertical.v-timeline {
  grid-row-gap: 24px;
}

>>> .drawer-section .v-timeline--vertical.v-timeline {
  /* grid-row-gap: 24px; */
  height: auto !important;
}

/* 
>>>.checked-box .mdi-checkbox-blank-outline{
color:#B00020;
} */
.fareDatas .justify {
  justify-content: end;
}
.fareDatas .pe {
  padding-right: 4px;
}
@media screen and (min-width: 998px) and (max-width: 1024px) {
  .fareDatas {
    padding: 0px !important;
  }
  .headfrom .font-change-14 {
    font-size: 18px;
  }
  .fare-summery .fareCard {
    max-width: 400px;
  }
}
@media screen and (min-width: 998px) and (max-width: 1024px) {
  .fareDatas {
    padding: 0px !important;
  }
  .headfrom .font-change-14 {
    font-size: 18px;
  }
  .fareDatas .justify {
    justify-content: center;
  }
  .fareDatas .pe {
    padding-right: 6px;
  }
}
@media screen and (max-width: 768px) {
  .fare-summery .fareCard {
    max-width: 100%;
  }
  .fareDatas .justify {
    justify-content: center;
  }
  .fareDatas .pe {
    padding-right: 2px;
  }
}

.true-sheet {
  border: 2px solid;
  border-radius: 5px;
  padding: 1px 8px;
  cursor: pointer;
}
.true-sheet:focus {
  background: lightgreen !important;
  border: 2px solid lightgreen !important;
}
.false-sheet {
  border: 2px solid gray;
  border-radius: 5px;
  background: gray;
}
.tru-icon {
  background: lightgreen;
  border: 2px solid lightgreen;
}
.tru-pass {
  background: #103769;
  color: white !important;
  /* border: 2px solid lightgreen; */
}
</style>
